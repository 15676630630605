import React, { useState } from 'react';
import {
    Drawer,
    Box,
    Typography,
    TextField,
    Rating,
    useTheme,
    useMediaQuery,
    Dialog,
    drawerClasses
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import AppButton from "../shared/AppButton";
import {useApi} from "../../contexts/ApiProvider";

function ProductWriteReview({ open, onClose }) {
    const api = useApi();
    const theme = useTheme();
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const [review, setReview] = useState({
        name: '',
        date: new Date().toISOString().split('T')[0],
        title: '',
        review: '',
        rating: 5,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setReview({ ...review, [name]: value });
    };

    const handleRatingChange = (event, newValue) => {
        setReview({ ...review, rating: newValue });
    };

    const handleSubmitReview = async () => {
        console.log('Review submitted: ', review);
        const { name, date, title, review: reviewText, rating: starRating } = review;
        try {
            const response = await api.post('/reviews', {
                name,
                date,
                title,
                review: reviewText,
                starRating,
            });
            if (response.ok) {
                console.log('Review saved successfully');
            } else {
                console.error('Failed to save review:', response.statusText);
            }
        } catch (error) {
            console.error('Error saving review:', error);
        }
        onClose(); // Consider closing only on success or providing feedback on failure
    };

    const RenderComponent = isDesktop ? Dialog : Drawer;
    const renderProps = isDesktop ?
        { onClose: () => onClose(), open: open, fullWidth: true, maxWidth: 'sm'} : { anchor: 'bottom', open: open, onClose: () => onClose() };

    return (
        <RenderComponent
            {...renderProps}
            PaperProps={{
                sx: {
                    minHeight: isDesktop ? 'auto' : '90%',
                    overflow: 'auto',
                    borderRadius: isDesktop ? '16px' : '16px 16px 0 0',
                    backgroundColor: theme.palette.offWhite.main,
                },
            }}
        >
            <Box
                sx={{ width: 'auto', padding: 2 }}
                role="presentation"
            >
                <Typography textAlign="center" component="div" fontWeight="700" fontSize="1.15rem" mb={3}>
                    Let us know what you think!
                </Typography>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%', marginBottom: '0.5rem' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '1rem',
                        }}
                    >
                        <Rating
                            name="rating"
                            value={review.rating}
                            onChange={handleRatingChange}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} />}
                        />
                    </Box>
                    <TextField
                        label="What's your name?"
                        name="name"
                        value={review.name}
                        onChange={handleInputChange}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '12px',
                                },
                                marginBottom: '1rem'
                            },
                        }}
                    />
                    <TextField
                        label="Give us a quick summary!"
                        name="title"
                        value={review.title}
                        onChange={handleInputChange}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '12px',
                                },
                            },
                        }}
                    />
                    <TextField
                        label="What do you think?"
                        name="review"
                        value={review.review}
                        multiline
                        rows={4}
                        onChange={handleInputChange}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '12px',
                                },
                            },
                        }}
                    />
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }} >
                        <AppButton onClick={handleSubmitReview}>Submit Review</AppButton>
                    </Box>
                </Box>
            </Box>
        </RenderComponent>
    );
}

export default ProductWriteReview;
