import {createTheme} from "@mui/material";

const theme = createTheme({
   palette: {
       primary: {
           main: '#C3E991'
       },
       secondary: {
           main: '#F9D982'
       },
       tertiary: {
           main: '#FF784F'
       },
       accent: {
           main: '#006437'
       },
       black: {
           main: '#171515',
           text: 'rgba(23, 21, 21, 0.8)',
       },
       offWhite: {
           main: '#FDF8F2'
       },
   },
   typography: {
       fontFamily: 'Graebenbach, Arial, sans-serif',
   },
});
export default theme;
