import React from 'react';
import {Box} from "@mui/material";
import ProductBanner from "../components/product/ProductBanner";
import ProductCaps from "../components/product/ProductCaps";
import ProductInfo from "../components/product/ProductInfo";
import ProductScience from "../components/product/ProductScience";
import ProductComments from "../components/product/ProductComments";
import ProductFAQ from "../components/product/ProductFAQ";
import styled from "@emotion/styled";

const Container = styled(Box)({
    margin: 'auto',
    maxWidth: '1800px',
})
function ProductPage(props) {
    return (
        <Container>
            <ProductBanner />
            <ProductCaps />
            <ProductInfo />
            <ProductScience />
            <ProductComments />
            <ProductFAQ />
        </Container>
    );
}

export default ProductPage;
