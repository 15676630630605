import React from 'react';
import styled from "@emotion/styled";
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography, useMediaQuery} from "@mui/material";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.offWhite.main,
    boxShadow: "none",
    '&:before': {
        display: "none",
    },
    '&.Mui-expanded': {
        margin: "0",
        borderRadius: '5.5rem',
    },
    '&.MuiAccordion-root': {
        border: '1px solid black',
        borderRadius: '1.67rem',
        '&:not(.Mui-expanded)': {
            borderBottom: '1px solid black',
        },
    },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'none',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        marginRight: '-21px',
    },
    padding: '0.4rem 1.66667rem'
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: '0.4rem 1.66667rem',
    paddingTop: '0',
}));

function AppAccordion({content, title, expandIcon}) {
    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <Box>
            <CustomAccordion>
                <CustomAccordionSummary
                    expandIcon={expandIcon}
                >
                    <Typography lineHeight="103.5%" fontSize={isDesktop ? "21px" : "0.88rem"} fontWeight="700">{title}</Typography>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <Typography lineHeight="127%" fontSize="0.88rem">
                        {content}
                    </Typography>
                </CustomAccordionDetails>
            </CustomAccordion>
        </Box>
    );
}

export default AppAccordion;
