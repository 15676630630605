import React from 'react';
import styled from '@emotion/styled';
import {Box, Typography} from "@mui/material";

// const BubbleContainer = styled(Box)(({ theme, backgroundColor }) => ({
//     width: '52vw',
//     height: '13.3vw',
//     display: 'flex',
//     alignItems: 'center',
//     borderRadius: '100px',
//     border: '1.6px solid #171515',
//     background: backgroundColor || theme.palette.tertiary.main,
// }));

const BubbleContainer = styled(Box)(({ theme, backgroundColor, width, height }) => ({
    width: width || '52vw',
    height: height || '13.3vw',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100px',
    border: '1.6px solid #171515',
    background: backgroundColor || theme.palette.tertiary.main,
}));

const LogoContainer = styled(Box)(({ theme }) => ({
    marginRight: '0.556rem',
    width: '1rem',
    height: '1rem',
    '& svg': {
        width: '100%',
        height: '100%',
    }
}));

const BubbleText = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.main,
    fontFamily: 'Graebenbach',
    fontWeight: 400,
    wordWrap: 'break-word',
    lineHeight: '110%'
}));
function AppBenefitsBubble({benefitText, icon, backgroundColor, fontSize, width, height, mr}) {
    return (
        <BubbleContainer backgroundColor={backgroundColor} width={width} height={height}>
            <Box display="flex" flexDirection="row" alignItems="center" style={{ marginLeft: '1.1rem', marginRight: mr?mr: 0}} >
                <LogoContainer>
                    {icon}
                </LogoContainer>
                <BubbleText style={{ fontSize: fontSize ? fontSize : '1rem'}}>{benefitText}</BubbleText>
            </Box>
        </BubbleContainer>
    );
}

export default AppBenefitsBubble;
