import React, {createContext, useContext, useState} from 'react';

const BasketContext = createContext();

function BasketProvider ({ children }) {
    const [badgeVisible, setBadgeVisible] = useState(false);

    return (
        <BasketContext.Provider value={{ badgeVisible, setBadgeVisible }}>
            {children}
        </BasketContext.Provider>
    );
}

export default BasketProvider;
export function useBasket() {
    return useContext(BasketContext);
}


