const BASE_API_URL = 'http://localhost:3001';

export default class ApiClient {
    constructor(onError) {
        this.onError = onError;
        this.base_url = BASE_API_URL + '/api';
    }

    async request(options) {
        let response = await this.requestInternal(options);
        if (response.status >= 500 && this.onError) {
            this.onError(response);
        }
        return response;
    }

    async requestInternal(options) {
        let query = new URLSearchParams(options.query || {}).toString();
        if (query !== '') {
            query = '?' + query;
        }
        let response;
        try {
            response = await fetch(this.base_url + options.url + query, {
                method: options.method,
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'omit',
                body: options.body ? JSON.stringify(options.body) : null,
            });
        } catch (error) {
            response = {
                ok: false,
                status: 500,
                json: async () => ({
                    code: 500,
                    message: 'The server is unresponsive',
                    description: error.toString(),
                }),
            };
        }

        return {
            ok: response.ok,
            status: response.status,
            body: response.status !== 204 ? await response.json() : null,
        };
    }

    async get(url, query, options) {
        return this.request({ method: 'GET', url, query, ...options });
    }

    async post(url, body, options) {
        return this.request({ method: 'POST', url, body, ...options });
    }

    async put(url, body, options) {
        return this.request({ method: 'PUT', url, body, ...options });
    }

    async delete(url, body, options) {
        return this.request({ method: 'DELETE', url, body, ...options });
    }
}
