import React, {useState} from 'react';
import {Box, FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";

const RadioSectionContainer = styled(Box)(({ theme }) => ({
    borderTop: '1px solid #000000',
    borderBottom: '1px solid #000000',
    display: 'flex',
    flexDirection: 'column',
}));

const RadioOptionContainer = styled(Box)(({ theme, isLast }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: isLast ? 'none' : '1px solid #000000',
    padding: '2px',
}));

function ProductPurchase({options}) {
    const isDesktop = useMediaQuery('(min-width:1024px)');

    const [selectedValue, setSelectedValue] = useState(options[0].value);
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <RadioSectionContainer>
            <RadioGroup value={selectedValue} onChange={handleRadioChange}>
                {options.map((option, index) => (
                    <RadioOptionContainer key={option.value} isLast={index === options.length - 1}>
                        <FormControlLabel
                            value={option.value}
                            control={
                                <Radio
                                    sx={{
                                        color: selectedValue === option.value ? 'primary.main' : 'black.main', // Color when not selected
                                        '&.Mui-checked': {
                                            color: 'accent.main',
                                        },
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1.2rem',
                                        },
                                    }}
                                />
                            }
                            label={<Typography lineHeight="103.5%" fontSize={isDesktop ? '22px' : '0.9rem'} fontWeight="700">{option.label}</Typography>}
                            style={{ marginRight: 0, flex: 1, paddingTop: '0.5rem', paddingBottom: '0.5rem'  }}
                        />
                        <Box>
                            <Typography color="#D8D4CF" component="span" lineHeight="103.5%" fontSize={isDesktop ? "28px" : "1.11rem"} fontWeight="700" style={{ textDecoration: 'line-through', marginRight: '8px' }}>
                                {option.originalPrice}
                            </Typography>
                            <Typography component="span" lineHeight="103.5%" fontSize={isDesktop ? "28px" : "1.11rem"} fontWeight="700">
                                {option.additionalText}
                            </Typography>
                        </Box>
                        {/*<Typography lineHeight="103.5%" fontSize={isDesktop ? "30px" : "1.11rem"} fontWeight="700">{option.additionalText}</Typography>*/}
                    </RadioOptionContainer>
                ))}
            </RadioGroup>
        </RadioSectionContainer>
    );
}

export default ProductPurchase;
