import React from 'react';
import styled from "@emotion/styled";
import {Box} from "@mui/material";
import AppSimpleCap from "../shared/AppSimpleCap";
import {ReactComponent as GlutenIcon} from "../../assets/icons/gluten_freeCapIcon.svg";
import {ReactComponent as VeganIcon} from "../../assets/icons/veganCapIcon.svg";
import {ReactComponent as NonGMO} from "../../assets/icons/non_gmoCapIcon.svg";
import {ReactComponent as NoFillers} from "../../assets/icons/no_fillersCapIcon.svg";
import {ReactComponent as NoBS} from "../../assets/icons/no_bsCapIcon.svg";
import {ReactComponent as LabTested} from "../../assets/icons/lab_testedCapIcon.svg";
import {ReactComponent as test} from "../../assets/icons/test.svg";

const Container = styled(Box)(({ theme }) => ({
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    '@media (min-width:1024px)': {
        padding: '0 2.83rem 0 1.38rem',
        height: 'auto'
    },
}));

const CapsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    gap: '2.22rem',
    marginTop: '4rem',
    marginBottom: '4.32rem',
    '@media (min-width:1024px)': {
        padding: '0 2.83rem 0 3.38rem',
        height: 'auto',
        gap: '76px'
    },
}));
function ProductCaps(props) {
    return (
        <Container>
            <CapsContainer>
                <AppSimpleCap title="Gluten free" innerIcon={<GlutenIcon />}  />
                <AppSimpleCap title="Vegan" innerIcon={<VeganIcon />} />
                <AppSimpleCap title="Non-GMO" innerIcon={<NonGMO />} />
                <AppSimpleCap title="No fillers" innerIcon={<NoFillers />} />
                <AppSimpleCap title="No BS" innerIcon={<NoBS />} />
                <AppSimpleCap title="Lab Tested" innerIcon={<LabTested />} />
            </CapsContainer>
        </Container>
    );
}

export default ProductCaps;
