import React from 'react';
import styled from '@emotion/styled';
import {Box, Typography, useMediaQuery} from "@mui/material";
import { Facebook, Instagram, YouTube } from '@mui/icons-material';
import {useNavigate} from "react-router-dom";

const Container = styled(Box)(({ theme }) => ({
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    marginBottom: '1rem',
    // padding: '0 2.83rem 0 3.38rem',
    backgroundColor: theme.palette.offWhite.main,
    '@media (min-width:1024px)': {
        marginTop: '1.25rem'
    },
}));

const Text = styled(Typography)(({ theme }) => ({
    // textAlign: 'center',
    fontSize: '0.75rem',
    color: theme.palette.black.main,
    fontFamily: 'Graebenbach',
    fontWeight: '700',
    lineHeight: '1.111rem',
    wordWrap: 'break-word',
    // '@media (min-width:1024px)': {
    //     fontSize: '1.2rem'
    // },
}));

const FDAText = styled(Text)(({ theme }) => ({
    fontWeight: '400',
}));

function HomeFooter(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const navigate = useNavigate();
    return (
        <Container>
            <Box display="flex" gap="0.5rem" alignSelf="center" alignItems="center">
                <Instagram style={{ cursor: 'pointer'}} />
                <Facebook style={{ cursor: 'pointer'}}/>
                <YouTube style={{ fontSize: '33px', cursor: 'pointer'}} />
            </Box>
            <Box style={{ width: isDesktop && '15%'}}>
                <Text style={{ cursor: 'pointer'}} onClick={() => navigate("/termsandconditions")}>
                    Terms of Service
                </Text>
                <Text style={{ cursor: 'pointer'}} onClick={() => navigate("/privacypolicy")}>
                    Privacy Policy
                </Text>
            </Box>
            <Box border={1} padding="0.5rem" mt="0.5rem" mb="0.1rem" width={isDesktop ? '35%' : '95%'}>
                <FDAText>
                    * These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.
                </FDAText>
            </Box>
            <Text alignSelf="center" mt={1.5}>
                &copy; 2024 BoxedBoom LLC. All rights reserved.
            </Text>
        </Container>
    );
}

export default HomeFooter;
