import React from 'react';
import styled from "@emotion/styled";
import {Card, CardContent, Typography} from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.secondary.main,
    borderRadius: 30,
    border: `1.64px ${theme.palette.black.main} solid`,
    height: '90vw',
    width: '80vw',
    '@media (min-width:1024px)': {
        height: '449px',
        width: '620px',
        alignItems: 'center',
    },
}));

const StyledContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
    textAlign: 'center',
    marginTop: '2rem',
    height: '100%',
    justifyContent: 'space-between',
    '@media (min-width:1024px)': {
        width: '64%',
    },
}));

const Title = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.black.text,
    fontFamily: 'Graebenbach',
    fontSize: '1.2em',
    fontWeight: 700,
    wordWrap: 'break-word',
    lineHeight: '110%',
}));

const ReviewText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.black.text,
    fontFamily: 'Graebenbach',
    wordWrap: 'break-word',
    lineHeight: '130%',
}));

const ReviewerDetails = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    color: theme.palette.black.text,
    wordWrap: 'break-word',
    marginBottom: '1.5rem'
}));

function AppReviewCard({title, review, reviewer}) {
    return (
        <StyledCard>
            <StyledContent>
                <Title>
                    {title}
                </Title>
                <ReviewText>
                    {review}
                </ReviewText>
                <ReviewerDetails>
                    {reviewer}
                </ReviewerDetails>
            </StyledContent>
        </StyledCard>
    );
}

export default AppReviewCard;
