import React, {useState} from 'react';
import styled from "@emotion/styled";
import {Box, Typography, Chip, IconButton, useMediaQuery} from "@mui/material";
import AppDivider from "./AppDivider";
import {ReactComponent as ThumbsUp} from "../../assets/icons/thumbsUpIcon.svg";
import {ReactComponent as ThumbsDown} from "../../assets/icons/thumbsDownIcon.svg";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.offWhite.main,
    height: 'auto',
    width: '100%',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
    },
}));

const HelpfulActions = styled(Box)({
    display: 'flex',
    marginLeft: '0.69rem',
    '& .MuiChip-root': {
        marginLeft: '0.14rem',
    },
});

const SectionColumn = styled(Box)(({ theme }) => ({
    width: '100%',
    '@media (min-width:1024px)': {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '8rem'
    },
}));

const StyledChip = styled(Chip)(({ theme, voteClicked }) => ({
    width: '3.4rem',
    height: '1.5rem',
    borderRadius: '0.75rem',
    borderColor: theme.palette.black.main,
    backgroundColor: voteClicked ? voteClicked : 'transparent',
    '& .MuiChip-label': {
        fontSize: '0.77rem',
        fontWeight: '700',
        lineHeight: '127%',
        padding: '0.4rem'
    },
    '&:not(:last-of-type)': {
        paddingRight: 0,
    },
}));

function AppComment({ name, date, title, review, rating }) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const averageRating = 4.5;
    const [counts, setCounts] = useState({
        thumbsUp: 125,
        thumbsDown: 3,
    });
    const [voteStatus, setVoteStatus] = useState({ thumbsUp: '', thumbsDown: '' });

    const handleVoteClick = (voteType) => {
        setCounts((prevCounts) => ({
            ...prevCounts,
            [voteType]: prevCounts[voteType] + 1,
        }));
        setVoteStatus({ ...voteStatus, [voteType]: voteType === 'thumbsUp' ? '#88ba7e' : '#ff7e7e' });
        setTimeout(() => {
            setVoteStatus((currentStatus) => ({
                ...currentStatus,
                [voteType]: '',
            }));
        }, 500);
    };

    return (
        <Container>
            <Box>
                <Rating style={{ fontSize: '22px', color: '#F9D982'}}
                        name="product-rating"
                        value={rating}
                        precision={1}
                        emptyIcon={<StarIcon style={{ fontSize: '20px', color: '#FDF8F2', stroke: '#171515', strokeWidth: '0.75px', marginTop: '0.05rem'  }} />}
                />
                <Typography mt="0.5rem" fontWeight="700" fontSize={isDesktop ? "22px" : "0.9rem"} lineHeight="103.5%" style={{ marginBottom: '0.28rem'}}>
                    {name}
                </Typography>
                <Typography fontSize={isDesktop ? "18px" : "0.9rem"} lineHeight="127%">
                    {date}
                </Typography>
            </Box>
            <SectionColumn>
                <Box style={{ marginTop: !isDesktop && '1.37rem'}}>
                    <Typography fontWeight="700" fontSize={isDesktop ? "38px" : "1.39rem"} lineHeight="100%" style={{ marginBottom: '0.5rem'}}>
                        {title}
                    </Typography>
                    <Typography lineHeight="127%" fontSize={isDesktop? "18px" : "0.89rem"}>
                        {review}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" style={{ marginTop: '1.07rem', marginBottom: '3.1rem'}}>
                    <Typography lineHeight="127%" fontSize={isDesktop? "18px" : "0.89rem"}>
                        Was this helpful
                    </Typography>
                    <HelpfulActions>
                        <StyledChip
                            icon={<ThumbsUp />}
                            label={counts.thumbsUp}
                            variant="outlined"
                            onClick={() => handleVoteClick('thumbsUp')}
                            voteClicked={voteStatus.thumbsUp}
                            style={{backgroundColor: voteStatus.thumbsUp}}
                        />
                        <StyledChip
                            icon={<ThumbsDown />}
                            label={counts.thumbsDown}
                            variant="outlined"
                            onClick={() => handleVoteClick('thumbsDown')}
                            voteClicked={voteStatus.thumbsDown}
                            style={{backgroundColor: voteStatus.thumbsDown}}
                        />
                    </HelpfulActions>
                </Box>
                <AppDivider  />
            </SectionColumn>
        </Container>
    );
}

export default AppComment;
