import React from 'react';
import styled from "@emotion/styled";
import {Box, Typography} from "@mui/material";
import {ReactComponent as Cap} from "../../assets/icons/whiteEllipse.svg";

const CapContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
});

const IconContainer = styled(Box)({
    position: 'relative',
    // width: '100%',
    // height: 'auto',
    width: '5.17rem',
    height: '2.9rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width:1024px)': {
        width: '118.6px',
        height: '68px'
    },
});

const DynamicIconWrapper = styled(Box)({
    position: 'absolute',
    // display: 'inline-flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    width: '28px',
    height: '28px',
    '@media (min-width:1024px)': {
        width: '35px',
        height: '35px'
    },
});

const TitleText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.black.main,
    fontSize: '0.9rem',
    fontFamily: 'Graebenbach',
    fontWeight: '700',
    lineHeight: '1.2222rem',
    wordWrap: 'break-word',
    marginTop: '1.11rem',
}));

function AppSimpleCap({title, innerIcon}) {
    return (
        <CapContainer>
            <IconContainer>
                <Cap />
                <DynamicIconWrapper>
                    {innerIcon}
                </DynamicIconWrapper>
            </IconContainer>
            <TitleText>{title}</TitleText>
        </CapContainer>
    );
}

export default AppSimpleCap;
