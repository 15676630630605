import React, {useState} from 'react';
import styled from "@emotion/styled";
import {Box, IconButton, Menu, MenuItem, Typography, useMediaQuery, Badge} from "@mui/material";
import {ReactComponent as Basket} from "../../assets/icons/basketIcon.svg";
import {ReactComponent as Hamburger} from "../../assets/icons/hamburgerIcon.svg";
import {useNavigate} from "react-router-dom";
import {useBasket} from "../../contexts/CartBadgeProvider";

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.offWhite.main,
    height: '4rem',
    padding: '0 .75rem',
    '@media (min-width:1024px)': {
        padding: '0 75px',
    },
}));

const ShopTypography = styled(Typography)({
    marginRight: '1.55rem',
    fontSize: "1.22rem",
    fontWeight: '700',
    '@media (min-width:1024px)': {
        fontSize: '24px',
        marginRight: '0rem'
    },
});

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiMenu-paper': {
        backgroundColor: theme.palette.offWhite.main,
        borderRadius: '10px',
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.black.text,
    fontSize: '0.9rem',
    '&:hover': {
        backgroundColor: theme.palette.offWhite.main,
    },
}));

const ShopAndBasketContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem'
});

function AppHeader(props) {
    const navigate = useNavigate();
    const { badgeVisible } = useBasket();
    let isDesktop = useMediaQuery('(min-width:1024px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    return (
        <Header>
            {!isDesktop && (
                <IconButton onClick={handleMenuOpen} sx={{ ml: -1 }}>
                    <Hamburger width="1.97rem" />
                </IconButton>
            )}
            <Typography onClick={() => navigate("/")} fontWeight="700" fontSize="1.25rem" sx={{ flexGrow: isDesktop ? 1 : undefined }}>
                BoxedBoom
            </Typography>
            {isDesktop ? (
                <ShopAndBasketContainer>
                    <ShopTypography onClick={() => navigate("/product")}>
                        Shop
                    </ShopTypography>
                    <IconButton onClick={() => navigate("/mycart")}>
                        <Badge badgeContent={badgeVisible ? 1 : 0} color="primary" >
                            <Basket width="39px" />
                        </Badge>
                    </IconButton>
                </ShopAndBasketContainer>
            ): (
                <IconButton onClick={() => navigate("/mycart")}>
                    <Badge badgeContent={badgeVisible ? 1 : 0} color="primary">
                        <Basket width="9.23vw"/>
                    </Badge>
                </IconButton>
            )}
            <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <StyledMenuItem onClick={handleMenuClose}>Shop</StyledMenuItem>
                <StyledMenuItem onClick={handleMenuClose}>Learn More</StyledMenuItem>
            </StyledMenu>
        </Header>
    );
}

export default AppHeader;
