import React from 'react';
import styled from "@emotion/styled";
import {Box, Typography} from "@mui/material";
import {ReactComponent as Cap} from "../../assets/icons/greenEllipse.svg";

const CapContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
});

const IconContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    height: '3.7822rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const DynamicIconWrapper = styled(Box)({
    position: 'absolute',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const TextContainer = styled(Box)({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.3889rem',
    display: 'flex',
    marginTop: '0.94rem',
    '@media (min-width:1024px)': {
        gap: '15px'
    },
});

const TitleText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.black.main,
    fontSize: '1.1111rem',
    fontFamily: 'Graebenbach',
    fontWeight: '700',
    lineHeight: '1.2222rem',
    wordWrap: 'break-word',
    '@media (min-width:1024px)': {
        fontSize: '35px'
    },
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.black.text,
    fontFamily: 'Graebenbach',
    fontWeight: '400',
    lineHeight: '1.3rem',
    wordWrap: 'break-word',
    '@media (min-width:1024px)': {
        fontSize: '1rem',
        width: '75%'
    },
}));

function AppCaps({title, description, innerIcon}) {
    return (
        <CapContainer>
            <IconContainer>
                <Cap />
                <DynamicIconWrapper>
                    {innerIcon}
                </DynamicIconWrapper>
            </IconContainer>
            <TextContainer>
                <TitleText>{title}</TitleText>
                <DescriptionText>{description}</DescriptionText>
            </TextContainer>
        </CapContainer>
    );
}

export default AppCaps;
