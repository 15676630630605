import React from 'react';
import styled from "@emotion/styled";
import {Box, Typography} from "@mui/material";
import AppButton from "../shared/AppButton";
import bgImageOne from '../../assets/images/pdish.jpg';
import bgImageTwo from '../../assets/images/gills.jpg';
import {useNavigate} from "react-router-dom";

const Container = styled(Box)(({ theme }) => ({
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.offWhite.main,
    paddingBottom: '20rem',
    justifyContent: 'center',
    '@media (min-width:1024px)': {
        flexDirection: 'row-reverse',
    },
}));

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3.39rem',
    marginBottom: '2.5rem',
    width: '85%',
    '@media (min-width:1024px)': {
        width: '50%',
        alignItems: 'flex-start',
        marginRight: '4rem',
    },
});

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.text,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontSize: '1.38rem',
    fontWeight: 700,
    lineHeight: '100%',
    wordWrap: 'break-word',
    marginBottom: '0.68rem',
    '@media (min-width:1024px)': {
        fontSize: '1.88rem',
        textAlign: 'left'
    },
}));

const Description = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.text,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontWeight: 400,
    lineHeight: '1.3rem',
    wordWrap: 'break-word',
    marginBottom: '0.91rem',
    '@media (min-width:1024px)': {
        fontSize: '0.99rem',
        textAlign: 'left'
    },
}));

const ImageStackContainer = styled(Box)({
    position: 'relative',
    width: 'calc(80vw + 2.25rem)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3rem',
    '@media (min-width:1024px)': {
        width: '50%',
        marginLeft: '3.5rem',
        marginRight: '5rem',
    },
});

const ImageContainerOne = styled(Box)(({ bgImage }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '80vw',
    height: '64vw',
    background: `url(${bgImageTwo}) `,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    borderRadius: '17.995px',
    '@media (min-width:1024px)': {
        width: '22.5rem',
        height: '18rem'
    },
}));

const ImageContainerTwo = styled(Box)(({ bgImage }) => ({
    position: 'absolute',
    top: '5.5rem',
    left: '2.5rem',
    width: '80vw',
    height: '64vw',
    background: `url(${bgImageOne}) `,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    borderRadius: '17.995px',
    '@media (min-width:1024px)': {
        width: '22.5rem',
        height: '18rem',
        top: '7rem'
    },
}));

function HomeLiquid(props) {
    const navigate = useNavigate();

    return (
        <Container>
            <TextContainer>
                <Title>
                    Liquid culture - Amplifying natures best
                </Title>
                <Description>
                    Engineered for potency, our ground-breaking liquid culture technology outperforms traditional methods, redefining the Mushroom supplement landscape as a whole.
                </Description>
                <AppButton onClick={() => navigate("/product")}>
                    Start now
                </AppButton>
            </TextContainer>
            <ImageStackContainer>
                <ImageContainerOne  />
                <ImageContainerTwo  />
            </ImageStackContainer>
        </Container>
    );
}

export default HomeLiquid;
