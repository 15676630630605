import React from 'react';
import styled from "@emotion/styled";
import {Box, Button, InputAdornment, TextField, Typography, useMediaQuery} from "@mui/material";
import AppDivider from "../shared/AppDivider";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {ReactComponent as Lock} from "../../assets/icons/lockIcon.svg";
import bottle from '../../assets/images/bottleSolo.png'


const SizeContainer = styled(Box)({
    margin: 'auto',
    maxWidth: '1600px',
})

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.offWhite.main,
    // justifyContent: 'center',
    height: 'auto',
    width: '100%',
    // margin: '0 0.78rem',
    alignItems: 'center',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
}));

const RightContentContainer = styled(Box)(({ theme }) => ({
    display: 'none',
    '@media (min-width:1024px)': {
        display: 'flex',
        width: '50%',
        justifyContent: 'center',
        height: '100vh',
    },
}));

const LeftContentContainer = styled(Box)(({ theme }) => ({
    width: '93%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: theme.palette.offWhite.main,
    '@media (min-width:1024px)': {
        display: 'flex',
        width: '50%',
        padding: '0rem 2rem'
        // justifyContent: 'center',
    },
}));

const SummaryContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1.67rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.black.main,
    // minHeight: '20rem',
    height: '587px',
    width: '513px',
    padding: '1.13rem',
}));

const SmartCheckoutContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
}));

const AddressContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.65rem',
    marginBottom: '2.56rem'
}));

const PaymentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.65rem',
    marginBottom: '1.78rem',
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '5.55rem',
        '& fieldset': {
            borderColor: theme.palette.black.main,
        },
    },
    '& .MuiInputBase-input': {
        height: '.5rem',
        paddingLeft: '1.12rem',
        '@media (min-width:1024px)': {
            height: '26px'
        },
    },
    '& .MuiInputBase-input::placeholder': {
        color: theme.palette.black.main,
        fontSize: '0.89rem',
        lineHeight: '127%',
    },
}));

const PromoField = styled(CustomTextField)(({ theme }) => ({
    marginBottom: '1.25rem',
    '& .MuiOutlinedInput-input': {
        paddingRight: '175px',
    },
    '& .MuiOutlinedInput-root': {
        position: 'relative',
        paddingRight: '0 !important',
    },
}));

const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    fontSize: '0.9rem',
    fontWeight: '700',
    lineHeight: '103.5%',
    width: '100%',
    height: '2.3rem',
    borderRadius: '5.55rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.black.main,
    textTransform: 'none',
    color: theme.palette.black.main,
    '@media (min-width:1024px)': {
        height: '56px',
        fontSize: '1rem',
        padding: '0.45rem',

    },
}));

const ProductContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '2rem',
    marginBottom: '1.7rem'
}));

const BottleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    borderRadius: '1.11rem',
    width: '6.31rem',
    height: '6.31rem',
    backgroundColor: '#fff',
    borderColor: theme.palette.black.main,
    borderWidth: '1px',
    borderStyle: 'solid',
}));

const ApplyButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: 'black',
    textTransform: 'none',
    borderTopRightRadius: '5.55rem',
    borderBottomRightRadius: '5.55rem',
    borderTopLeftRadius: '5.55rem',
    borderBottomLeftRadius: '5.55rem',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '175px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '700',
    fontSize: '22px',
}));

const validationSchema = Yup.object().shape({
    fullName: Yup.string()
        .required("Required"),
    addressLine1: Yup.string()
        .required("Required"),
    city: Yup.string()
        .required("Required"),
    state: Yup.string()
        .required("Required"),
    zip: Yup.string()
        .required("Required"),
    cardNumber: Yup.string()
        .required("Required").matches(/^\d{16}$/, "Card number must be 16 digits"),
    expirationDate: Yup.string()
        .required("Required"),
    securityCode: Yup.string()
        .required("Required").matches(/^\d{3}$/, "Security code must be 3 digits"),
    nameOnCard: Yup.string()
        .required("Required"),
});

const initialValues = {
    fullName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    cardNumber: "",
    expirationDate: "",
    securityCode: "",
    nameOnCard: ""
};

function CheckoutForm(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <SizeContainer>
            <Box display="flex" justifyContent="center" style={{ backgroundColor: '#FDF8F2'}}>
                <Typography fontWeight="700" lineHeight="100%" style={{ fontSize: isDesktop ? '38px' : '1rem', marginTop: isDesktop ? '3.3rem' : '1rem',  marginBottom: isDesktop ? '3.5rem' : '1rem'}}>
                    Check out
                </Typography>
            </Box>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => {
                console.log("Form submitted with values:", values);
            }}>
                <Form>
                    <Container>
                        <LeftContentContainer>
                            {/*<AppDivider text="Express check out" size={isDesktop ? "22px" : "0.89rem"}/>*/}
                            {/*<SmartCheckoutContainer>*/}
                            {/*</SmartCheckoutContainer>*/}
                            {/*<AppDivider text="Or" size={isDesktop ? "22px" : "0.89rem"}/>*/}
                            <AddressContainer>
                                <Typography fontWeight="700" lineHeight="103.5%" size={isDesktop ? "22px" : "0.89rem"} style={{ marginBottom: '0.5rem'}}>
                                    Delivery
                                </Typography>
                                <Field
                                    type="text"
                                    name="fullName"
                                    as={CustomTextField}
                                    placeholder="Full Name"
                                    variant="outlined"
                                    helperText={<ErrorMessage name="fullName" />}
                                    fullWidth
                                />
                                <Field
                                    type="text"
                                    name="addressLine1"
                                    as={CustomTextField}
                                    placeholder="Address line 1"
                                    variant="outlined"
                                    helperText={<ErrorMessage name="addressLine1" />}
                                    fullWidth
                                />
                                <Field
                                    type="text"
                                    name="addressLine2"
                                    as={CustomTextField}
                                    placeholder="Address line 2"
                                    variant="outlined"
                                    fullWidth
                                />
                                <Box display="flex" gap="0.3rem">
                                    <Field
                                        type="text"
                                        name="city"
                                        as={CustomTextField}
                                        placeholder="City"
                                        variant="outlined"
                                        helperText={<ErrorMessage name="city" />}
                                        fullWidth
                                    />
                                    <Field
                                        type="text"
                                        name="state"
                                        as={CustomTextField}
                                        placeholder="State"
                                        variant="outlined"
                                        helperText={<ErrorMessage name="state" />}
                                        fullWidth
                                    />
                                    <Field
                                        type="text"
                                        name="zip"
                                        as={CustomTextField}
                                        placeholder="Zip"
                                        variant="outlined"
                                        helperText={<ErrorMessage name="zip" />}
                                        fullWidth
                                    />
                                </Box>
                            </AddressContainer>
                            <PaymentContainer>
                                <Typography fontWeight="700" lineHeight="103.5%" size={isDesktop ? "22px" : "0.89rem"} style={{ marginBottom: '0.5rem'}}>
                                    Payment
                                </Typography>
                                <Field
                                    type="text"
                                    name="cardNumber"
                                    as={CustomTextField}
                                    placeholder="Card Number"
                                    inputProps={{ maxLength: 16 }}
                                    variant="outlined"
                                    helperText={<ErrorMessage name="cardNumber" />}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={{ marginLeft: '20px' }}>
                                                <Lock width="90%" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Box display="flex" gap="0.65rem" width="100%">
                                    <Field
                                        type="text"
                                        name="expirationDate"
                                        as={CustomTextField}
                                        placeholder="Exp. Date (MM/YY)"
                                        variant="outlined"
                                        helperText={<ErrorMessage name="expirationDate" />}
                                        fullWidth
                                    />
                                    <Field
                                        type="text"
                                        name="securityCode"
                                        inputProps={{ maxLength: 3 }}
                                        as={CustomTextField}
                                        placeholder="Security Code"
                                        variant="outlined"
                                        helperText={<ErrorMessage name="securityCode" />}
                                        fullWidth
                                    />
                                </Box>
                                <Field
                                    type="text"
                                    name="nameOnCard"
                                    as={CustomTextField}
                                    placeholder="Name on card"
                                    variant="outlined"
                                    helperText={<ErrorMessage name="nameOnCard" />}
                                    fullWidth
                                />
                            </PaymentContainer>
                            <CustomButton type="submit">
                                Pay now
                            </CustomButton>
                        </LeftContentContainer>
                        <RightContentContainer>
                            <SummaryContainer>
                                <Typography fontWeight="700" size={isDesktop ? "22px" : "0.89rem"}>
                                    Order Summary
                                </Typography>
                                <ProductContainer>
                                    <BottleContainer>
                                        <img src={bottle}   alt="boxedboom bottle"/>
                                    </BottleContainer>
                                    <Box display="flex" flexGrow="1" justifyContent="space-between" padding="0rem 1rem ">
                                        <Typography lineHeight="130%" width="67%" >
                                            Morel mushroom extract
                                        </Typography>
                                        <Typography lineHeight="130%">
                                            xx$
                                        </Typography>
                                    </Box>
                                </ProductContainer>
                                <AppDivider marginBottom="1.25rem" />
                                <PromoField fullWidth variant="outlined" placeholder="Promo Code" InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <ApplyButton>
                                                Apply
                                            </ApplyButton>
                                        </InputAdornment>
                                    )
                                }} />
                                <AppDivider marginBottom="0.79rem" />
                                <Box display="flex" flexDirection="column" gap={isDesktop ? "1.5rem" : "0.5rem"} style={{ marginBottom: '0.9rem'}} mt={isDesktop && '1rem'}>
                                    <Box display='flex'flexDirection="row" justifyContent="space-between" alignItems="center">
                                        <Typography lineHeight="130%">
                                            Subtotal
                                        </Typography>
                                        <Typography lineHeight="130%">
                                            $12.00
                                        </Typography>
                                    </Box>
                                    <Box display='flex' flexDirection="row" justifyContent="space-between" alignItems="center" mb={isDesktop && '1rem'}>
                                        <Typography lineHeight="130%">
                                            Shipping
                                        </Typography>
                                        <Typography lineHeight="130%">
                                            -
                                        </Typography>
                                    </Box>
                                </Box>
                                <AppDivider marginBottom="0.91rem" />
                                <Box display='flex' flexDirection="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '1.31rem'}}>
                                    <Typography lineHeight="130%">
                                        Total
                                    </Typography>
                                    <Typography lineHeight="103.5%" fontWeight="700">
                                        $12.00
                                    </Typography>
                                </Box>
                            </SummaryContainer>
                        </RightContentContainer>
                    </Container>
                </Form>
            </Formik>
        </SizeContainer>
    );
}

export default CheckoutForm;
