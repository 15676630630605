import React, { useContext, createContext, useCallback, useMemo } from 'react';
import ApiClient from "../services/ApiConfig";

const ApiContext = createContext();
function ApiProvider({ children }) {
    const onError = useCallback(() => {
        console.error('No access to api or error.');
    }, []);

    const api = useMemo(() => new ApiClient(onError), [onError]);

    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
}

export default ApiProvider;

export function useApi() {
    return useContext(ApiContext);
}
