import React, {useState} from 'react';
import styled from "@emotion/styled";
import {Box, Typography, useMediaQuery} from "@mui/material";
import Rating from "@mui/material/Rating";
import StarIcon from '@mui/icons-material/Star';
import AppHeader from "../shared/AppHeader";
import AppBenefitsBubble from "../shared/AppBenefitsBubble";
import {ReactComponent as Shield} from "../../assets/icons/shieldIcon.svg";
import {ReactComponent as Target} from "../../assets/icons/targetIcon.svg";
import {ReactComponent as Wink} from "../../assets/icons/smileyWinkIcon.svg";
import {ReactComponent as Arrow} from "../../assets/icons/arrowIcon.svg";
import bannerImageProduct from '../../assets/images/bannerProduct.jpg';
import ProductPurchase from "./ProductPurchase";
import AppButton from "../shared/AppButton";
import {useBasket} from "../../contexts/CartBadgeProvider";

const Banner = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.offWhite.main,
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
    },
}));

const PhotoContainer = styled(Box)(({ theme }) => ({
    borderRadius: '1.67rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    width: '92vw',
    height: '95.5vw',
    backgroundImage: `url(${bannerImageProduct})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '@media (min-width:1024px)': {
        minWidth: '50%',
        width: '50%',
        height: '900px',
        borderRadius: '0',
    },
}));

const CountContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: '1.5rem',
    width: '7.72rem',
    height: '2.5rem',
    borderRadius: '5.55rem',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.offWhite.main,
    border: '1px solid #171515',
    gap: '10px',
    '@media (min-width:1024px)': {
        // height: '70px',
        // width: '235px',
        visibility: 'hidden',
    },
}));

const NumberOption = styled(({ isActive, ...props }) => <Box {...props} />)(({ theme, isActive }) => ({
    width: '2.055rem',
    height: '2.11rem',
    borderRadius: '50%',
    fontFamily: 'Graebenbach',
    fontWeight: '700',
    fontSize: '0.889rem',
    lineHeight: '0.92rem',
    textAlign: 'center',
    color: '#171515',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isActive ? '#F9D982' : '#FDF8F2',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
}));

const PurchaseContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
    width: 'calc(100% - 2rem)',
    boxSizing: 'border-box',
    '@media (min-width:1024px)': {
        width: '50%',
        padding: '0rem 3.25rem 0rem 1.75rem'

    },
}));

const BubblesContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.28rem',
    maxWidth: '80vw',
    '@media (min-width:1024px)': {
        width: '60%',
    },
}));

const BenefitsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.93rem',
    gap: '0.56rem',
    marginBottom: '2.14rem'
}));

const Title = styled(Typography)({
    fontWeight: '700',
    fontFamily: 'Graebenbach',
    fontSize: '1.63rem',
    lineHeight: '100%',
    marginBottom: '1rem',
    '@media (min-width:1024px)': {
        fontSize: '50px',
    },
});

const BenefitItem = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& svg': {
        flexShrink: 0,
    }
});

const BenefitText = styled(Typography)(({ theme }) => ({
    marginLeft: '0.78rem',
    lineHeight: '130%',
}));

const RatingsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem'
    // '@media (min-width:1024px)': {
    //     flexDirection: 'row',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    // },
}));

const BenefitItemComponent = ({ text }) => (
    <BenefitItem>
        <Arrow />
        <BenefitText>
            {text}
        </BenefitText>
    </BenefitItem>
);

function ProductBanner(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const { setBadgeVisible } = useBasket();
    const [activeSelection, setActiveSelection] = useState(1);
    const radioOptions = [
        { value: 'option1', label: 'One time purchase', additionalText: '$11' },
        { value: 'option2', label: 'Subscribe', additionalText: '$11', originalPrice: '$29' },
    ];
    const [numOfBottles, setNumOfBottles] = useState(1);
    const totalPrice = numOfBottles * 49;

    const handleAddBottle = () => {
        setNumOfBottles((current) => current + 1);
    };
    const handleRemoveBottle = () => {
        setNumOfBottles((current) => Math.max(1, current - 1));
    };
    const averageRating = 3.9;
    const numberOfReviews = 2;

    const handleOutOfStockClick = () => {
        setBadgeVisible(true); // Show the badge
    };

    return (
        <>
            <AppHeader />
            <Banner>
                <PhotoContainer>
                    <CountContainer>
                        {['01', '02', '03'].map((num) => (
                            <NumberOption
                                key={num}
                                isActive={num === activeSelection}
                                onClick={() => setActiveSelection(num)}
                            >
                                {num}
                            </NumberOption>
                        ))}
                    </CountContainer>
                </PhotoContainer>
                <PurchaseContainer>
                    <RatingsContainer>
                        <Rating style={{ fontSize: '22px', color: '#F9D982'}}
                                name="product-rating"
                                value={averageRating}
                                precision={1}
                                readOnly
                                emptyIcon={<StarIcon style={{ fontSize: '20px', color: '#FDF8F2', stroke: '#171515', strokeWidth: '0.75px', marginTop: '0.05rem'  }} />}
                        />
                        <Typography fontSize="16px" fontWeight="400" lineHeight="103.5%">
                            {averageRating.toFixed(1)} | {numberOfReviews} reviews
                        </Typography>
                    </RatingsContainer>
                    <Title>
                        Morel mushroom extract
                    </Title>
                    <BubblesContainer>
                        <AppBenefitsBubble width="120px" mr="1rem" height="1.72rem" fontSize="0.9rem" backgroundColor="#FDF8F2" benefitText="focus" icon={<Target />} />
                        <AppBenefitsBubble mr="1rem" height="1.72rem" fontSize="0.9rem" backgroundColor="#FDF8F2" benefitText="mood" icon={<Wink />}/>
                        <AppBenefitsBubble mr="0.5rem" height="1.72rem" fontSize="0.9rem" backgroundColor="#FDF8F2" benefitText="immunity" icon={<Shield />}/>
                    </BubblesContainer>
                    <BenefitsContainer>
                        <BenefitItemComponent text="Boosts mental clarity and focus" />
                        <BenefitItemComponent text="Enhances immune defense mechanisms" />
                        <BenefitItemComponent text="Natural combatant against free radicals" />
                        <BenefitItemComponent text="Great for those seeking a cognitive edge and robust health" />
                        <BenefitItemComponent text="30 day supply" />
                    </BenefitsContainer>
                    <Typography fontWeight="700" lineHeight="103.5%" fontSize={isDesktop ? '22px' : '0.9rem'} style={{ marginBottom: '1.63rem'}}>
                        See supplement facts
                    </Typography>
                    <ProductPurchase options={radioOptions} />
                    <Box display="flex" justifyContent="center" style={{ marginTop: isDesktop ? '4rem' : '2.77rem', marginBottom: '1.9rem', width: '100%'}}>
                        {/*<AppButton width={isDesktop && '98%'} fontWeight="700" buttonColor={"#C3E991"} onAdd={handleAddBottle} onRemove={handleRemoveBottle} onClick={() => console.log('clicked total')}>*/}
                        {/*    {`${numOfBottles} Bottle${numOfBottles > 1 ? 's' : ''} - $${totalPrice}`}*/}
                        {/*</AppButton>*/}
                        <AppButton width={isDesktop && '98%'} fontWeight="700" buttonColor={"#C3E991"} onAdd={handleAddBottle} onRemove={handleRemoveBottle} onClick={handleOutOfStockClick}>
                            Sorry, out of stock!
                        </AppButton>
                    </Box>
                </PurchaseContainer>
            </Banner>
        </>
    );
}

export default ProductBanner;
