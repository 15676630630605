import React from 'react';
import styled from "@emotion/styled";
import {Box, Typography} from "@mui/material";
import {ReactComponent as Ellipse} from "../../assets/icons/bigEllipse.svg";
import BackgroundImage from '../../assets/images/morelOnTree.jpg'
import AppButton from "../shared/AppButton";
import {useNavigate} from "react-router-dom";

const Container = styled(Box)(({ theme }) => ({
    height: '46.11em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: `url(${BackgroundImage}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center -2.25rem',
    '@media (min-width:1024px)': {
        backgroundPosition: 'center',
    },
}));

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2,
    width: '90%',
    marginBottom: '5.11rem',
    '@media (min-width:1024px)': {
        width: '42%',
    },
});

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.text,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontSize: '1.38em',
    fontWeight: 700,
    lineHeight: '100%',
    wordWrap: 'break-word',
    marginBottom: '0.56rem',
    '@media (min-width:1024px)': {
        fontSize: '42px',
    },
}));

const Description = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.text,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontWeight: 400,
    lineHeight: '1.3em',
    wordWrap: 'break-word',
    marginBottom: '1.11rem',
    '@media (min-width:1024px)': {
        fontSize: '22px',
    },
}));

const HalfCircleContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 'auto',
}));

const BEStyled = styled(Ellipse)({
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    width: '100%',
    '@media (min-width:1024px)': {
        height: '150%'
    },
});
function HomeFirst(props) {
    const navigate = useNavigate();

    return (
        <Container>
            <HalfCircleContainer>
                <BEStyled />
                <TextContainer>
                    <Title>
                        We are the first<br/>to do this
                    </Title>
                    <Description>
                        Morel mushrooms are very rare and sought after. We are the first company on the market offering a supplement product. Provide the feeling of experiencing something unique and beneficial in ways that have not been offered yet.
                    </Description>
                    <AppButton onClick={() => navigate("/product")}>
                        Start now
                    </AppButton>
                </TextContainer>
            </HalfCircleContainer>
        </Container>
    );
}

export default HomeFirst;
