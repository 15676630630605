import React from 'react';
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

const DividerContainer = styled(Box)(({ width, marginBottom, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: width || '100%',
    marginBottom: marginBottom || '1.37rem',
}));

const Line = styled(Box)({
    height: '1px',
    flexGrow: 1,
    backgroundColor: 'black',
});

function AppDivider({ text, size, marginBottom, width }) {
    return (
        <DividerContainer width={width} marginBottom={marginBottom}>
            <Line />
            {text && <Typography marginLeft="0.75rem" marginRight="0.75rem" lineHeight="103.5%" fontWeight="700" fontSize={size}>{text}</Typography>}
            {text && <Line />}
        </DividerContainer>
    );
}

export default AppDivider;


