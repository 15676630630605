import React from 'react';
import styled from "@emotion/styled";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import AppHeader from "../components/shared/AppHeader";
import HomeFooter from "../components/home/HomeFooter";
import theme from "../config/brandConfig";

const Container = styled(Box)({
    margin: 'auto',
    maxWidth: '1800px',
})

const BodyText = styled(Typography)({
    fontSize: '0.85rem'
})

const PrivacyPolicyRow = ({ category, examples, collected }) => {
    return (
        <TableRow>
            <TableCell sx={{ fontSize: '0.75rem', verticalAlign: 'top' }} component="th" scope="row">
                {category}
            </TableCell>
            <TableCell sx={{ fontSize: '0.75rem', verticalAlign: 'top' }}>
                {examples}
            </TableCell>
            <TableCell sx={{ fontSize: '0.75rem', verticalAlign: 'top' }}>
                {collected}
            </TableCell>
        </TableRow>
    );
};

const rowData = [
    {
        category: 'A. Identifiers',
        examples: 'Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name',
        collected: 'YES'
    },
    {
        category: 'B. Personal information as defined in the California Customer Records statute',
        examples: 'Name, contact information, education, employment, employment history, and financial information',
        collected: 'YES'
    },
    {
        category: 'C. Protected classification characteristics under state or federal law',
        examples: 'Gender and date of birth',
        collected: 'YES'
    },
    {
        category: 'D. Commercial information',
        examples: 'Transaction information, purchase history, financial details, and payment information',
        collected: 'YES'
    },
    {
        category: 'E. Biometric information',
        examples: 'Fingerprints and voiceprints',
        collected: 'NO'
    },
    {
        category: 'F. Internet or other similar network activity',
        examples: 'Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements',
        collected: 'NO'
    },
    {
        category: 'G. Geolocation data',
        examples: 'Device location',
        collected: 'NO'
    },
    {
        category: 'H. Audio, electronic, visual, thermal, olfactory, or similar information',
        examples: 'Images and audio, video or call recordings created in connection with our business activities',
        collected: 'NO'
    },
    {
        category: 'I. Professional or employment-related information',
        examples: 'Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us',
        collected: 'NO'
    },
    {
        category: 'J. Education Information',
        examples: 'Student records and directory information',
        collected: 'NO'
    },
    {
        category: 'K. Inferences drawn from collected personal information',
        examples: 'Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics',
        collected: 'NO'
    },
    {
        category: 'L. Sensitive personal Information',
        examples: '',
        collected: 'NO'
    }
];

function PrivacyPolicyPage(props) {
    return (
        <Container>
            <AppHeader />
            <Box px="1rem" mt="1.75rem" gap="1rem" display="flex" flexDirection="column">
                <Typography fontWeight="700" fontSize="1.75rem">
                    Privacy Policy
                </Typography>
                <BodyText fontWeight="700">
                    Lasted updated: January 16, 2024
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem">
                <BodyText>
                    This privacy notice for BOXEDBOOM LLC ("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"), describes how and why <strong>we</strong> might collect, store, use, and/or share (<strong>"process"</strong>) your information when you use our services (<strong>"Services"</strong>), such as when you:
                </BodyText>
                <BodyText component="ul">
                    <li>Visit our website at https://boxedboom.com, or any website of ours that links to this privacy notice</li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </BodyText>
                <BodyText>
                    Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at privacy@boxedboom.com.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    SUMMARY OF KEY POINTS
                </BodyText>
                <BodyText fontWeight="700">
                    This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
                </BodyText>
                <BodyText>
                    <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. link here about personal information you disclose to us.
                </BodyText>
                <BodyText>
                    <strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.
                </BodyText>
                <BodyText>
                    <strong>Do we receive any information from third parties?</strong> We do not receive any information from third parties.
                </BodyText>
                <BodyText>
                    <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. link here about how we process your information.
                </BodyText>
                <BodyText>
                    <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. link here about when and with whom we share your personal information.
                </BodyText>
                <BodyText>
                    <strong>How do we keep your information safe?</strong> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. link here about how we keep your information safe.
                </BodyText>
                <BodyText>
                    <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. link here about your privacy rights.
                </BodyText>
                <BodyText>
                    <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by visiting link here or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                </BodyText>
                <BodyText>
                    Want to learn more about what we do with any information we collect? Review the privacy notice in full.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    1. WHAT INFORMATION DO WE COLLECT?
                </BodyText>
                <BodyText>
                    <strong>Personal information you disclose to us</strong>
                </BodyText>
                <BodyText>
                    In Short: We collect personal information that you provide to us.
                </BodyText>
                <BodyText>
                    We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                </BodyText>
                <BodyText>
                    <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                </BodyText>
                <Box pl="2rem" mb="0.5rem">
                    <BodyText component="li">names</BodyText>
                    <BodyText component="li">email addresses</BodyText>
                    <BodyText component="li">mailing addresses</BodyText>
                    <BodyText component="li">usernames</BodyText>
                    <BodyText component="li">passwords</BodyText>
                    <BodyText component="li">contact preferences</BodyText>
                    <BodyText component="li">billing addresses</BodyText>
                </Box>
                <BodyText>
                    <strong>Sensitive Information.</strong> We do not process sensitive information.
                </BodyText>
                <BodyText>
                    <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Payment Cloud Inc, Apple, and Google.
                </BodyText>
                <BodyText>
                    All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                </BodyText>
                <BodyText>
                    <strong>Information automatically collected</strong>
                </BodyText>
                <BodyText>
                    In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                </BodyText>
                <BodyText>
                    We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                </BodyText>
                <BodyText>
                    Like many businesses, we also collect information through cookies and similar technologies.
                </BodyText>
                <BodyText>
                    The information we collect includes:
                </BodyText>
                <Box pl="1rem" mb="0.5rem">
                    <BodyText component="li">Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</BodyText>
                    <BodyText component="li">Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</BodyText>
                    <BodyText component="li">Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</BodyText>
                </Box>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                </BodyText>
                <BodyText>
                    In Short:  We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                </BodyText>
                <BodyText fontWeight="700">
                    We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                </BodyText>
                <Box pl="1rem" mb="0.5rem">
                    <BodyText component="li"><strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong> We may process your information so you can create and log in to your account, as well as keep your account in working order.</BodyText>
                    <BodyText component="li"><strong>To deliver and facilitate delivery of services to the user.</strong> We may process your information to provide you with the requested service.</BodyText>
                    <BodyText component="li"><strong>To respond to user inquiries/offer support to users.</strong> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</BodyText>
                    <BodyText component="li"><strong>To fulfill and manage your orders.</strong> We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</BodyText>
                    <BodyText component="li"><strong>To request feedback.</strong> We may process your information when necessary to request feedback and to contact you about your use of our Services.</BodyText>
                    <BodyText component="li"><strong>To send you marketing and promotional communications.</strong> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below.</BodyText>
                    <BodyText component="li"><strong>To deliver targeted advertising to you.</strong> We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.</BodyText>
                    <BodyText component="li"><strong>To post testimonials.</strong> We post testimonials on our Services that may contain personal information.</BodyText>
                    <BodyText component="li"><strong>To evaluate and improve our Services, products, marketing, and your experience.</strong> We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</BodyText>
                    <BodyText component="li"><strong>To identify usage trends.</strong> We may process information about how you use our Services to better understand how they are being used so we can improve them.</BodyText>
                    <BodyText component="li"><strong>To determine the effectiveness of our marketing and promotional campaigns.</strong> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</BodyText>
                </Box>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </BodyText>
                <BodyText>
                    In Short:  We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                </BodyText>
                <BodyText>
                    We may need to share your personal information in the following situations:
                </BodyText>
                <BodyText pl="1rem" component="li"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </BodyText>
                <BodyText>
                    In Short:  We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                </BodyText>
                <BodyText>
                    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    5. HOW LONG DO WE KEEP YOUR INFORMATION?
                </BodyText>
                <BodyText>
                    In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
                </BodyText>
                <BodyText>
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than   the period of time in which users have an account with us.
                </BodyText>
                <BodyText>
                    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </BodyText>
                <BodyText>
                    In Short: We aim to protect your personal information through a system of organizational and technical security measures
                </BodyText>
                <BodyText>
                    We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    7. DO WE COLLECT INFORMATION FROM MINORS?
                </BodyText>
                <BodyText>
                    In Short: We do not knowingly collect data from or market to children under 18 years of age.
                </BodyText>
                <BodyText>
                    We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at info@boxedboom.com.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    8. WHAT ARE YOUR PRIVACY RIGHTS?
                </BodyText>
                <BodyText>
                    In Short:  You may review, change, or terminate your account at any time.
                </BodyText>
                <BodyText>
                    <strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                </BodyText>
                <BodyText>
                    However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                </BodyText>
                <BodyText>
                    <strong><u>Opting out of marketing and promotional communications:</u></strong> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
                </BodyText>
                <Box>
                    <BodyText fontWeight="700">
                        Account Information
                    </BodyText>
                    <BodyText>
                        If you would at any time like to review or change the information in your account or terminate your account, you can:
                    </BodyText>
                    <Box pl="1rem">
                        <BodyText component="li">Log in to your account settings and update your user account.
                        </BodyText>
                        <BodyText component="li">Contact us using the contact information provided.</BodyText>
                    </Box>
                    <BodyText>
                        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                    </BodyText>
                </Box>
                <BodyText>
                    <strong><u>Cookies and similar technologies:</u></strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
                </BodyText>
                <BodyText>
                    If you have questions or comments about your privacy rights, you may email us at privacy@boxedboom.com.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    9. CONTROLS FOR DO-NOT-TRACK FEATURES
                </BodyText>
                <BodyText>
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </BodyText>
                <BodyText>
                    In Short: If you are a resident of California, Colorado, Connecticut, Utah or Virginia, you are granted specific rights regarding access to your personal information.
                </BodyText>
                <BodyText fontWeight="700">
                    What categories of personal information do we collect?
                </BodyText>
                <BodyText>
                    We have collected the following categories of personal information in the past twelve (12) months:
                </BodyText>
                <TableContainer component={Paper} elevation={0} sx={{ marginBottom: '2rem', backgroundColor: theme.palette.offWhite.main}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: '700'}} >Category</TableCell>
                                <TableCell sx={{ fontWeight: '700'}} >Examples</TableCell>
                                <TableCell sx={{ fontWeight: '700'}} >Collected</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowData.map((row, index) => (
                                <PrivacyPolicyRow
                                    key={index}
                                    category={row.category}
                                    examples={row.examples}
                                    collected={row.collected}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box>
                    <BodyText>
                        We will use and retain the collected personal information as needed to provide the Services or for:
                    </BodyText>
                    <Box pl="0.5rem" mb="0.5rem">
                        <BodyText component="li">Category A - As long as the user has an account with us</BodyText>
                        <BodyText component="li">Category B - As long as the user has an account with us</BodyText>
                        <BodyText component="li">Category C - As long as the user has an account with us</BodyText>
                        <BodyText component="li">Category D - As long as the user has an account with us</BodyText>
                    </Box>
                </Box>
                <Box>
                    <BodyText>
                        We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:                    </BodyText>
                    <Box pl="0.5rem" mb="0.5rem">
                        <BodyText component="li">Receiving help through our customer support channels;
                        </BodyText>
                        <BodyText component="li">Participation in customer surveys or contests; and</BodyText>
                        <BodyText component="li">Facilitation in the delivery of our Services and to respond to your inquiries.</BodyText>
                    </Box>
                </Box>
                <BodyText fontWeight="700">
                    How do we use and share your personal information?
                </BodyText>
                <BodyText>
                    Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"
                </BodyText>
                <BodyText fontWeight="700">
                    Will your information be shared with anyone else?
                </BodyText>
                <BodyText>
                    We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
                </BodyText>
                <BodyText>
                    We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
                </BodyText>
                <BodyText>
                    We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
                </BodyText>
                <BodyText fontWeight="700">
                    California Residents
                </BodyText>
                <BodyText>
                    California Civil Code Section 1798.83, also known as the "Shine The Light" law permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                    If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
                </BodyText>
                <BodyText fontWeight="700">
                    CCPA Privacy Notice
                </BodyText>
                <BodyText>
                    This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below.
                </BodyText>
                <Box>
                    <BodyText>
                        The California Code of Regulations defines a "residents" as:
                    </BodyText>
                    <BodyText>
                        (1) every individual who is in the State of California for other than a temporary or transitory purpose and
                        (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose
                    </BodyText>
                </Box>
                <BodyText>
                    All other individuals are defined as "non-residents."
                </BodyText>
                <BodyText>
                    If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
                </BodyText>
                <BodyText fontWeight="700">
                    Your rights with respect to your personal data
                </BodyText>
                <BodyText>
                    <u>Right to request deletion of the data — Request to delete</u>
                </BodyText>
                <BodyText>
                    You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
                </BodyText>
                <BodyText>
                    <u>Right to be informed — Request to know</u>
                </BodyText>
                <BodyText>
                    Depending on the circumstances, you have a right to know:
                </BodyText>
                <Box pl="0.5rem" mb="0.5rem">
                    <BodyText component="li">whether we collect and use your personal information</BodyText>
                    <BodyText component="li">the categories of personal information that we collect;</BodyText>
                    <BodyText component="li">the purposes for which the collected personal information is used;
                    </BodyText>
                    <BodyText component="li">whether we sell or share personal information to third parties;</BodyText>
                    <BodyText component="li">the categories of personal information that we sold, shared, or disclosed for a business purpose;</BodyText>
                    <BodyText component="li">the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</BodyText>
                    <BodyText component="li">the business or commercial purpose for collecting, selling, or sharing personal information; and</BodyText>
                    <BodyText component="li">the specific pieces of personal information we collected about you.</BodyText>
                </Box>
                <BodyText>
                    In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
                </BodyText>
                <BodyText>
                    <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u>
                </BodyText>
                <BodyText>
                    We will not discriminate against you if you exercise your privacy rights.
                </BodyText>
                <BodyText>
                    <u>Right to Limit Use and Disclosure of Sensitive Personal Information</u>
                </BodyText>
                <BodyText>
                    We do not process consumer's sensitive personal information.
                </BodyText>
                <BodyText>
                    <u>Verification process</u>
                </BodyText>
                <BodyText>
                    Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
                </BodyText>
                <BodyText>
                    We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
                </BodyText>
                <Box>
                    <BodyText>
                        <u>Other privacy rights</u>
                    </BodyText>
                    <BodyText component="li">You may object to the processing of your personal information.</BodyText>
                    <BodyText component="li">You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</BodyText>
                    <BodyText component="li">You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</BodyText>
                    <BodyText component="li">You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.</BodyText>
                </Box>
                <BodyText>
                    To exercise these rights, you can contact us by visiting http://www.boxedboom.com/data-request, by email at ccpa@boxedboom.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
                </BodyText>
                <BodyText fontWeight="700">
                    Colorado Residents
                </BodyText>
                <Box>
                    <BodyText>
                        This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.
                    </BodyText>
                    <BodyText component="li">Right to be informed whether or not we are processing your personal data</BodyText>
                    <BodyText component="li">Right to access your personal data</BodyText>
                    <BodyText component="li">Right to correct inaccuracies in your personal data</BodyText>
                    <BodyText component="li">Right to request deletion of your personal data</BodyText>
                    <BodyText component="li">Right to obtain a copy of the personal data you previously shared with us</BodyText>
                    <BodyText component="li">Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</BodyText>
                    <BodyText>
                        To submit a request to exercise these rights described above, please email privacy@boxedboom.com or visit http://www.boxedboom.com/data-request.
                    </BodyText>
                </Box>
                <BodyText>
                    If we decline to take action regarding your request and you wish to appeal our decision, please email us at privacy@boxedboom.com. Within forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.
                </BodyText>
                <BodyText fontWeight="700">
                    Connecticut Residents
                </BodyText>
                <Box>
                    <BodyText>
                        This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.                    </BodyText>
                    <BodyText component="li">Right to be informed whether or not we are processing your personal data</BodyText>
                    <BodyText component="li">Right to access your personal data</BodyText>
                    <BodyText component="li">Right to correct inaccuracies in your personal data</BodyText>
                    <BodyText component="li">Right to request deletion of your personal data</BodyText>
                    <BodyText component="li">Right to obtain a copy of the personal data you previously shared with us</BodyText>
                    <BodyText component="li">Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</BodyText>
                    <BodyText>
                        To submit a request to exercise these rights described above, please email privacy@boxedboom.com or visit http://www.boxedboom.com/data-request.
                    </BodyText>
                </Box>
                <BodyText>
                    If we decline to take action regarding your request and you wish to appeal our decision, please email us at privacy@boxedboom.com. Within forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.
                </BodyText>
                <BodyText fontWeight="700">
                    Utah Residents
                </BodyText>
                <Box>
                    <BodyText>
                        This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.                    </BodyText>
                    <BodyText component="li">Right to be informed whether or not we are processing your personal data</BodyText>
                    <BodyText component="li">Right to access your personal data</BodyText>
                    <BodyText component="li">Right to request deletion of your personal data</BodyText>
                    <BodyText component="li">Right to obtain a copy of the personal data you previously shared with us</BodyText>
                    <BodyText component="li">Right to opt out of the processing of your personal data if it is used for targeted advertising or the sale of personal data</BodyText>
                    <BodyText>
                        To submit a request to exercise these rights described above, please email privacy@boxedboom.com or visit http://www.boxedboom.com/data-request.
                    </BodyText>
                </Box>
                <BodyText fontWeight="700">
                    Virginia Residents
                </BodyText>
                <Box>
                    <BodyText>
                        Under the Virginia Consumer Data Protection Act (VCDPA):
                    </BodyText>
                    <BodyText>
                        "Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.
                    </BodyText>
                    <BodyText>
                        "Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.
                    </BodyText>
                    <BodyText>
                        "Sale of personal data" means the exchange of personal data for monetary consideration.
                    </BodyText>
                    <BodyText>
                        If this definition of "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.
                    </BodyText>
                </Box>
                <Box>
                    <BodyText>
                        <u>Your rights with respect to your personal data</u>
                    </BodyText>
                    <Box pl="0.5rem" mb="0.5rem">
                        <BodyText component="li">Right to be informed whether or not we are processing your personal data</BodyText>
                        <BodyText component="li">Right to access your personal data</BodyText>
                        <BodyText>Right to correct inaccuracies in your personal data</BodyText>
                        <BodyText component="li">Right to request deletion of your personal data</BodyText>
                        <BodyText component="li">Right to obtain a copy of the personal data you previously shared with us</BodyText>
                        <BodyText component="li">Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</BodyText>
                        <BodyText>
                            <u>Exercise your rights provided under the Virginia VCDPA</u>
                        </BodyText>
                    </Box>
                </Box>
                <BodyText>
                    You may contact us by email at privacy@boxedboom.com or visit http://www.boxedboom.com/data-request.
                </BodyText>
                <BodyText>
                    If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
                </BodyText>
                <BodyText>
                    <u>Verification process</u>
                </BodyText>
                <BodyText>
                    We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.
                </BodyText>
                <BodyText>
                    Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.
                </BodyText>
                <BodyText>
                    <u>Right to appeal</u>
                </BodyText>
                <BodyText>
                    If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at privacy@boxedboom.com. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the Attorney General to submit a complaint.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    11. DO WE MAKE UPDATES TO THIS NOTICE?
                </BodyText>
                <BodyText>
                    In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </BodyText>
                <BodyText>
                    We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </BodyText>
                <BodyText>
                    If you have questions or comments about this notice, you may email us at info@boxedboom.com or contact us by post at:
                </BodyText>
                <BodyText>
                    BOXEDBOOM LLC
                    2753 E Broadway Rd Ste 101-487
                    Mesa, AZ 85204
                    United States
                </BodyText>
            </Box>
            <Box px="1rem" mt="1rem" mb="1.5rem" gap="0.5rem" display="flex" flexDirection="column">
                <BodyText fontWeight="700">
                    13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </BodyText>
                <BodyText>
                    Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit:  http://www.boxedboom.com/data-request.
                </BodyText>
            </Box>
            <HomeFooter />
        </Container>
    );
}

export default PrivacyPolicyPage;
