import React from 'react';
import styled from "@emotion/styled";
import {Box, Typography, useMediaQuery} from "@mui/material";
import BackgroundImage from "../../assets/images/morelOnTree.jpg";
import theme from "../../config/brandConfig";
import AppResearchCard from "../shared/AppResearchCard";

const Container = styled(Box)(({ theme }) => ({
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${BackgroundImage}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center -2.25rem',
    backgroundColor: theme.palette.offWhite.main,
    '@media (min-width:1024px)': {
        padding: '0 75px',

    },
}));

const TextContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5.4rem',
    width: '65%',
    marginLeft: '1rem',
    marginBottom: '1.125rem',
    '@media (min-width:1024px)': {
        width: '33%',
        marginLeft: 0,
        marginBottom: '2rem',
        marginTop: '7rem'
    },
}));

const CardsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '3.39rem',
    '& > :nth-of-type(1)': {
        marginLeft: '4.125rem',
    },
    '& > :nth-of-type(2)': {
        marginLeft: '1rem',
    },
    '@media (min-width:1024px)': {
        flexDirection: 'row',
        gap: '1rem',
        '& > :nth-of-type(1)': {
            marginLeft: '0rem',
        },
        '& > :nth-of-type(2)': {
            marginLeft: '0rem',
        },
        marginBottom: '200px'
    },
}));

function ProductScience(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <Container>
            <TextContainer>
                <Typography lineHeight="100%" fontSize={isDesktop ? "68px" : "2.33rem"} fontWeight="700" color={theme.palette.offWhite.main}>
                    Backed by science
                </Typography>
            </TextContainer>
            <CardsContainer>
                <AppResearchCard />
                {/*<AppResearchCard />*/}
                <AppResearchCard />
            </CardsContainer>
        </Container>
    );
}

export default ProductScience;
