import React, {useEffect, useState} from 'react';
import {Box, Typography, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";
import AppButton from "../shared/AppButton";
import AppComment from "../shared/AppComment";
import AppDivider from "../shared/AppDivider";
import {ReactComponent as Arrow} from "../../assets/icons/reviewArrowIcon.svg";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import ProductWriteReview from "./ProductWriteReview";
import {useApi} from "../../contexts/ApiProvider";

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.offWhite.main,
    height: 'auto',
}));

const TitleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '2.56rem',
    '@media (min-width:1024px)': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '93%',
        marginTop: '7rem'
    },
}));

const CommentsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
}));

// const PaginationContainer = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     width: '85%',
//     marginBottom: '6.63rem',
//     '@media (min-width:1024px)': {
//         flexDirection: 'row',
//         justifyContent: 'space-between',
//     },
// }));

const PaginationContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '6.63rem',
    width: '84vw',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
        width: '25%'
    },
}));

const PaginationNumberContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '.78rem',
});

const RatingsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}));

const DeskContainer = styled(Box)(({ theme }) => ({
    '@media (min-width:1024px)': {
        flexDirection: 'column',
        display: 'flex',
        textAlign: 'left',
        flex: 1
    },
}));

function ProductComments(props) {
    const api = useApi();
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const [isReviewDrawerOpen, setIsReviewDrawerOpen] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    // const totalPages = 5;
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    const [currentPage, setCurrentPage] = useState(1);
    const reviewsPerPage = 5;
    const indexOfLastReview = currentPage * reviewsPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
    const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);


    const formatNumber = (number) => {
        return number < 10 ? `0${number}` : number.toString();
    };
    const averageRating = 4.5;
    const numberOfReviews = 2;

    const toggleReviewDrawer = (open) => () => {
        setIsReviewDrawerOpen(open);
    };

    const handlePrevClick = () => {
        setCurrentPage(current => Math.max(current - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(current => Math.min(current + 1, totalPages));
    };

    // useEffect(() => {
    //     const fetchProductReviews = async () => {
    //         try {
    //             const response = await api.get('/reviews');
    //             console.log(response);
    //             if (response.status === 200) {
    //                 setReviews(response.body);
    //                 console.log(response.body)
    //             } else {
    //                 console.error('Failed to fetch reviews:', response);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching reviews:', error);
    //         }
    //     };
    //     fetchProductReviews();
    // }, [api]);

    useEffect(() => {
        const fetchProductReviews = async () => {
            try {
                const response = await api.get('/reviews');
                if (response.status === 200) {
                    setReviews(response.body);
                    setTotalPages(Math.ceil(response.body.length / reviewsPerPage));
                } else {
                    console.error('Failed to fetch reviews:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };
        fetchProductReviews();
    }, [api]);


    return (
        <Container>
            <TitleContainer>
                <DeskContainer>
                    <Typography sx={{ mb: isDesktop && "15px"}} fontSize={isDesktop? "64px" : "2.33rem"} fontWeight="700" lineHeight="100%" textAlign={isDesktop ? 'left' : 'center'}>
                        What they say
                    </Typography>
                    <RatingsContainer>
                        <Rating style={{ fontSize: '22px', color: '#F9D982'}}
                                name="product-rating"
                                value={averageRating}
                                precision={1}
                                readOnly
                                emptyIcon={<StarIcon style={{ fontSize: '20px', color: '#FDF8F2', stroke: '#171515', strokeWidth: '0.75px', marginTop: '0.05rem'  }} />}
                        />
                        <Typography fontSize="16px" fontWeight="700" lineHeight="103.5%">
                            {averageRating.toFixed(1)} | {numberOfReviews} reviews
                        </Typography>
                    </RatingsContainer>
                </DeskContainer>
                {isDesktop && (
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <AppButton onClick={toggleReviewDrawer(true)} width="200px" fontWeight="700">
                            Write a review
                        </AppButton>
                    </Box>
                )}
            </TitleContainer>
            {!isDesktop && (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
                    <AppButton onClick={toggleReviewDrawer(true)}>
                        Write a review
                    </AppButton>
                </Box>
            )}
            <AppDivider width="90%"/>
            {/*<CommentsContainer>*/}
            {/*    {reviews.map((review, index) => (*/}
            {/*        <AppComment key={index} title={review.title} review={review.review} date={review.date} name={review.name} rating={review.star_rating} />*/}
            {/*    ))}*/}
            {/*</CommentsContainer>*/}
            <CommentsContainer>
                {currentReviews.map((review, index) => (
                    <AppComment key={index} title={review.title} review={review.review} date={review.date} name={review.name} rating={review.star_rating} />
                ))}
            </CommentsContainer>
            {/*<PaginationContainer>*/}
            {/*    <Arrow style={{ transform: 'rotate(180deg)', width: '2rem', height: '1.67rem'}} />*/}
            {/*    <PaginationNumberContainer>*/}
            {/*        {pageNumbers.map(number => (*/}
            {/*            <Typography key={number} fontWeight="700"  lineHeight="127%">*/}
            {/*                {formatNumber(number)}*/}
            {/*            </Typography>*/}
            {/*        ))}*/}
            {/*    </PaginationNumberContainer>*/}
            {/*    <Arrow style={{ width: '2rem', height: '1.67rem'}}/>*/}
            {/*</PaginationContainer>*/}
            <PaginationContainer>
                <Arrow style={{ transform: 'rotate(180deg)', cursor: 'pointer',  width: '2rem', height: '1.67rem' }} onClick={handlePrevClick} />
                <PaginationNumberContainer>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
                        <Typography
                            key={number}
                            onClick={() => setCurrentPage(number)}
                            fontWeight="700"
                            lineHeight="127%"
                            sx={{
                                cursor: 'pointer',
                                color: number === currentPage ? '#006437' : 'inherit',
                            }}
                        >
                            {formatNumber(number)}
                        </Typography>
                    ))}
                </PaginationNumberContainer>
                <Arrow style={{ cursor: 'pointer', width: '2rem', height: '1.67rem' }} onClick={handleNextClick} />
            </PaginationContainer>
            <ProductWriteReview
                open={isReviewDrawerOpen}
                onClose={toggleReviewDrawer(false)}
            />
        </Container>
    );
}
export default ProductComments;
