import React from 'react';
import {Box} from "@mui/material";
import Cart from "../components/checkout/Cart";
import styled from "@emotion/styled";
import CheckoutForm from "../components/checkout/CheckoutForm";

const Container = styled(Box)({
    margin: 'auto',
    maxWidth: '1800px',
})

function CheckoutPage(props) {
    return (
        <Container>
            {/*<Cart />*/}
            <CheckoutForm />
        </Container>
    );
}

export default CheckoutPage;
