import React from "react";
import {ThemeProvider} from "@mui/material";
import theme from "./config/brandConfig";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import CheckoutPage from "./pages/CheckoutPage";
import TofSPage from "./pages/TofSPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ScrollToTop from "./components/shared/ScrollToTop";
import Cart from "./components/checkout/Cart";
import ApiProvider from "./contexts/ApiProvider";
import BasketProvider from "./contexts/CartBadgeProvider";
function App() {
  return (
      <ThemeProvider theme={theme}>
        <Router>
            <ApiProvider>
                <BasketProvider>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/product" element={<ProductPage />} />
                        <Route path="/checkout" element={<CheckoutPage />} />
                        <Route path="/mycart" element={<Cart />} />
                        <Route path="/termsandconditions" element={<TofSPage />} />
                        <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
                        <Route path="*" element={<HomePage />} />
                    </Routes>
                    <ScrollToTop />
                </BasketProvider>
            </ApiProvider>
        </Router>
      </ThemeProvider>
  );
}

export default App;
