import React from 'react';
import {Box, Button, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";
import {ReactComponent as Plus} from "../../assets/icons/plusIcon.svg";
import {ReactComponent as Minus} from "../../assets/icons/minusIcon.svg";

const ShadowLayer = styled(Box)(({ theme, width }) => ({
    width: '13.72rem',
    height: '2.944rem',
    position: 'absolute',
    left: '0.667rem',
    top: '0.278rem',
    background: theme.palette.accent.main,
    borderRadius: '1.669rem',
    border: '0.033rem solid',
    zIndex: 1,
    '@media (min-width:1024px)': {
        width: width ? width : '9.72rem',
    },
}));

const StyledButton = styled(Button)(({ theme, width, buttonColor, fontWeight, fontSize }) => ({
    width: '14.167rem',
    height: '2.778rem',
    background: buttonColor || theme.palette.offWhite.main,
    borderRadius: '5.556rem',
    border: '0.056rem solid #171515',
    textTransform: 'none',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 2,
    fontSize: fontSize || '1rem',
    fontWeight: fontWeight || 400,
    lineHeight: '1.3rem',
    fontFamily: 'Graebenbach',
    color: theme.palette.black.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.556rem',
    '&:hover': {
        background: theme.palette.offWhite.main,
    },
    '@media (min-width:1024px)': {
        width: width ? width : '9.72rem',
    },
}));
function AppButton({children, buttonColor, fontWeight, fontSize, onAdd, onRemove, width, ...props}) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const containerWidth = width ? width : isDesktop ? '9.72rem' : '14.176rem';

    return (
        <Box sx={{ position: 'relative', width: containerWidth, height: '3.771rem' }}>
            <ShadowLayer width={width} />
            <StyledButton fontSize={fontSize} buttonColor={buttonColor} fontWeight={fontWeight} width={width} {...props}>
                {onRemove && <Minus onClick={onRemove} style={{ position: 'absolute', left: 10, marginLeft: '0.5rem' }} />}
                {children}
                {onAdd && <Plus onClick={onAdd} style={{ position: 'absolute', right: 10, marginRight: '0.35rem' }} />}
            </StyledButton>
        </Box>
    );
}

export default AppButton;
