import React, {useState} from 'react';
import styled from "@emotion/styled";
import {Box, Typography, Button, useMediaQuery} from "@mui/material";
import {ReactComponent as CloseIcon} from "../../assets/icons/expandIcon.svg";
import {ReactComponent as Plus} from "../../assets/icons/chipPlusIcon.svg";
import {ReactComponent as Minus} from "../../assets/icons/chipMinusIcon.svg";
import AppGooglePay from "../shared/AppGooglePay";
import {ReactComponent as GooglePay} from "../../assets/icons/google-pay-mark.svg";
import {useNavigate} from "react-router-dom";
import bottle from '../../assets/images/bottleSolo.png'
import bannerImageProduct from '../../assets/images/bannerProduct.jpg';
import AppHeader from "../shared/AppHeader";

const SizeContainer = styled(Box)({
    margin: 'auto',
    maxWidth: '1800px',
})

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    // flexDirection: 'column',
    backgroundColor: theme.palette.offWhite.main,
    // justifyContent: 'space-between',
    // height: '100vh',
    // width: '100%',
    // margin: '0 0.78rem',
    // alignItems: 'center',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
    },
}));

const LeftImageContainer = styled(Box)(({ theme }) => ({
    display: 'none',
    '@media (min-width:1024px)': {
        display: 'flex',
        width: '50%',
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(${bannerImageProduct})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
}));

const RightContentContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100vh',
    '@media (min-width:1024px)': {
        width: '50%',
    },
}));

const Heading = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '1.46rem',
    '@media (min-width:1024px)': {
        marginTop: '2.4rem',
    },
}));

const ProductContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1.91rem',
    justifyContent: 'space-between',
    '@media (min-width:1024px)': {
        marginTop: '2.5rem',
    },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '36vh',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    '@media (min-width:1024px)': {

    },
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.36rem',
    height: 'auto',
    '@media (min-width:1024px)': {
        gap: '0.86rem',
    },
}));

const BottomTwoButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.45rem',
    width: '100%',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '0.76rem',
    },
}));

const BottleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    borderRadius: '1.11rem',
    // width: '33vw',
    // height: '33vw',
    width: '29vw',
    height: '29vw',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderColor: theme.palette.black.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    '@media (min-width:1024px)': {
        width: '5.31rem',
        height: '5.31rem',
        marginRight: '0.5rem'
    },
}));

const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.offWhite.main,
    fontSize: '0.9rem',
    fontWeight: '700',
    lineHeight: '103.5%',
    width: '100%',
    height: '2.78rem',
    borderRadius: '5.55rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.black.main,
    textTransform: 'none',
    color: theme.palette.black.main,
    '@media (min-width:1024px)': {
        height: '2.68rem',
        fontSize: '1rem',
        padding: '0.45rem',
    },
}));

const CustomChip = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '5rem',
    height: '1.7rem',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5.55rem',
    border: `1px solid ${theme.palette.black.main}`,
    padding: '0rem 0.1rem',
    '@media (min-width:1024px)': {
        width: '4.75rem'
    },
}));

const IconWrapper = styled(Box)({
    cursor: 'pointer',
    display: 'flex',
    margin: '0rem,'
});

function Cart(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);

    const handleIncrement = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
        }
    };

    const costPerBottle = 49;
    const totalPrice = quantity * costPerBottle;

    return (
        <SizeContainer>
            <Container>
                <LeftImageContainer>
                    <Typography onClick={() => navigate("/")} fontWeight="700" fontSize="1.25rem" ml="4rem" mt="1rem">
                        BoxedBoom
                    </Typography>
                    {/*<AppHeader />*/}
                    {/*<Box>*/}
                    {/*    image here*/}
                    {/*</Box>*/}
                </LeftImageContainer>
                <RightContentContainer>
                    <Box display="flex" flexDirection="column" style={{ width: isDesktop ? '78%': '93%'}}>
                        <Heading>
                            <Box display="flex" style={{ flexDirection: isDesktop ? 'column': 'row', gap: '0.36rem'}}>
                                <Typography fontWeight="700" lineHeight="110%" style={{ fontSize: isDesktop ? '35px': '1.11rem'}}>
                                    Your Cart
                                </Typography>
                                <Typography style={{ alignSelf: isDesktop ? 'flex-start' : 'flex-end',fontWeight: '400', fontSize: isDesktop ? '22px' :'0.89rem', lineHeight: '127%' }}>
                                    ({quantity} items)
                                </Typography>
                            </Box>
                            <CloseIcon onClick={()=>navigate("/product")} style={{ transform: 'rotate(45deg)', fill: '#fff', height: isDesktop ? '39px' :'2.14rem', width: isDesktop ? '39px' :'2.14rem'}}/>
                        </Heading>
                        <ProductContainer>
                            <Box display="flex" flexDirection="row" height="100%">
                                <BottleContainer>
                                    <img style={{ minWidth: '225%'}} src={bottle} alt="boxedBoom supplements bottle"/>
                                </BottleContainer>
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ marginLeft: '0.52rem'}}>
                                    <Box display="flex" flexDirection="column" gap="0.25rem" style={{ width: isDesktop ?'100%' : '90%', marginTop: isDesktop && '0.5rem'}}>
                                        <Typography fontWeight="700" lineHeight="103.5%" fontSize={isDesktop ? "22px" : "0.89rem"}>
                                            Morel mushroom extract
                                        </Typography>
                                        <CustomChip>
                                            <IconWrapper onClick={handleDecrement}>
                                                <Minus style={{ width: isDesktop ? '1.45rem':'1.45rem',  height: isDesktop ? '1.45rem':'1.45rem'}} />
                                            </IconWrapper>
                                            <Typography fontSize={isDesktop ? "22px" : "0.89rem"} fontWeight="700" lineHeight="103.5%">
                                            {quantity}
                                            </Typography>
                                            <IconWrapper onClick={handleIncrement}>
                                                <Plus style={{ width: isDesktop ? '1.45rem':'1.45rem',  height: isDesktop ? '1.45rem':'1.45rem'}}/>
                                            </IconWrapper>
                                        </CustomChip>
                                    </Box>
                                    {!isDesktop && (
                                        <Typography lineHeight="103.5%" fontSize="0.89rem" style={{ opacity: 0.85, color: '#171515' }}>
                                            Remove
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            {!isDesktop && (
                                <Typography fontWeight="700" lineHeight="103.5%" fontSize="0.89rem">
                                    ${totalPrice}
                                </Typography>
                            )}
                            {isDesktop && (
                                <Box display="flex" flexDirection="column" justifyContent="space-between">
                                    <Typography fontWeight="700" lineHeight="103.5%" fontSize="22px" alignSelf="flex-end">
                                        ${totalPrice}
                                    </Typography>
                                    <Typography lineHeight="103.5%" fontSize="0.89rem" style={{ opacity: 0.85, color: '#171515' }}>
                                        Remove
                                    </Typography>
                                </Box>
                            )}
                        </ProductContainer>
                    </Box>
                    <BottomContainer>
                        <Box display="flex" flexDirection="column" style={{ marginBottom: isDesktop ? '3rem':'1rem', width: isDesktop ? '78%': '93%'}} >
                            <Box style={{ marginTop: '2.59rem', marginBottom: isDesktop ? '1.31rem':'2rem'}} display="flex" flexDirection="row" justifyContent="space-between">
                                <Typography lineHeight="110%" fontWeight="700" style={{ fontSize: isDesktop ? '35px' : '1.11rem'}}>
                                    Subtotal
                                </Typography>
                                <Typography lineHeight="110%" fontWeight="700" style={{ fontSize: isDesktop ? '35px' : '1.11rem'}}>
                                    ${totalPrice}
                                </Typography>
                            </Box>
                            <ButtonsContainer>
                                <CustomButton onClick={()=>navigate("/checkout")}>
                                    Check out
                                </CustomButton>
                                <Typography fontWeight="700" textAlign="center" fontSize="0.89rem">
                                    Or
                                </Typography>
                                <BottomTwoButtonsContainer>
                                    <CustomButton>
                                        Check out
                                    </CustomButton>
                                    {/*<AppGooglePay />*/}
                                    <CustomButton>
                                        Apple Pay
                                    </CustomButton>
                                </BottomTwoButtonsContainer>
                            </ButtonsContainer>
                        </Box>
                    </BottomContainer>
                </RightContentContainer>
            </Container>
        </SizeContainer>
    );
}

export default Cart;
