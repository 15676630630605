import React from 'react';
import {Box, Typography, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";
import AppAccordion from "../shared/AppAccordion";
import {ReactComponent as ExpandIcon} from "../../assets/icons/expandIcon.svg";
import HandImage from '../../assets/images/handModelCap.jpg';

const Container = styled(Box)(({ theme }) => ({
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.offWhite.main,
    '@media (min-width:1024px)': {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
}));

const PictureContainer = styled(Box)(({ theme, bgImage }) => ({
    width: '92vw',
    height: '100vw',
    marginTop: '2.5rem',
    alignSelf: 'center',
    borderRadius: '1.76rem',
    background:`url(${HandImage}) no-repeat `,
    backgroundSize: 'cover',
    '@media (min-width:1024px)': {
        width: '610px',
        height: '665px',
        background: `url(${HandImage}) no-repeat`,
        backgroundSize: 'cover',
        marginTop: '5rem',
        marginBottom: '5rem',
    },
}));

const AccordionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3rem',
    marginBottom: '3.83rem',
    gap: '0.56rem',
    width: '92vw',
    marginLeft: '0.8rem',
    '@media (min-width:1024px)': {
        height: 'auto',
        marginLeft: '0rem',
        width: '40%'
    },
}));
function ProductInfo(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <Container>
            {/*{isDesktop ? (*/}
            {/*    <Box display="flex" >*/}
            {/*        <PictureContainer />*/}
            {/*    </Box>*/}
            {/*) : (*/}
            {/*    <PictureContainer />*/}
            {/*)}*/}
            <PictureContainer />
            <AccordionContainer>
                <Typography fontWeight="700" fontSize={ isDesktop ? "52px" : "1.78rem"} mb={isDesktop && '0.5rem'}>
                    Pure & Simple
                </Typography>
                <AppAccordion expandIcon={<ExpandIcon />} title="Suggested use" content="Take two capsules daily with food and water or as recommended by a healthcare professional." />
                <AppAccordion expandIcon={<ExpandIcon />} title="Something else" content="Take two capsules daily with food and water or as recommended by a healthcare professional." />
            </AccordionContainer>
        </Container>
    );
}

export default ProductInfo;
