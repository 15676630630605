import React from 'react';
import styled from "@emotion/styled";
import {Box, Card, Typography} from "@mui/material";
import {ReactComponent as Icon} from "../../assets/icons/shieldIcon.svg";
import {ReactComponent as Arrow} from "../../assets/icons/reviewArrowIcon.svg";

const StyledCard = styled(Card)(({ theme }) => ({
    background: theme.palette.offWhite.main,
    borderRadius: '1.67rem',
    border: '1.64px #171515 solid',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.25rem',
    justifyContent: 'space-between',
    width: '65vw',
    height: '84vw',
    '@media (min-width:1024px)': {
        height: '530px',
        width: '402px'
    },
}));

const TopContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
}));

const BottomContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '1.39rem',
    color: theme.palette.black.main,
    fontFamily: 'Graebenbach',
    fontWeight: 700,
    marginTop: '0.76rem',
    lineHeight: '100%'
}));

const Studies = styled(Typography)(({ theme }) => ({
    fontSize: '0.9rem',
    color: theme.palette.black.main,
    fontFamily: 'Graebenbach',
    lineHeight: '127%',
    marginBottom: '0.2rem'
}));

const StudiesNums = styled(Typography)(({ theme }) => ({
    fontSize: '1.11rem',
    color: theme.palette.black.main,
    fontFamily: 'Graebenbach',
    fontWeight: 700,
    lineHeight: '110%',
}));


function AppResearchCard(props) {
    const [isFlipped, setIsFlipped] = React.useState(false);
    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    }
    return (
        <StyledCard>
            <TopContainer>
                <Icon />
                <Title>
                    Boosts mental clarity and focus
                </Title>
            </TopContainer>
            <BottomContainer>
                <Studies>
                    Number of studies
                </Studies>
                <StudiesNums>
                    7
                </StudiesNums>
                <Studies style={{ marginTop: '0.5rem'}}>
                    Years of studies
                </Studies>
                <Box mt={-1.5} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <StudiesNums>
                        2003-22
                    </StudiesNums>
                    <Arrow style={{ width: '2rem'}} />
                </Box>
            </BottomContainer>
        </StyledCard>
    );
}

export default AppResearchCard;
