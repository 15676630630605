import React from 'react';
import {Box, Typography} from "@mui/material";
import styled from "@emotion/styled";
import AppButton from "../shared/AppButton";
import {ReactComponent as Bolt} from "../../assets/icons/boltIcon.svg";
import AppBenefitsBubble from "../shared/AppBenefitsBubble";
import bgImage from '../../assets/images/holdingHand.jpg';
import {useNavigate} from "react-router-dom";


const Container = styled(Box)(({ theme }) => ({
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.offWhite.main,
    paddingBottom: '3rem',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
}));

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '4.14rem',
    marginBottom: '3.3rem',
    width: '85%',
    '@media (min-width:1024px)': {
        alignItems: 'flex-start',
        marginTop: 0,
        width: '50%',
        marginLeft: '3rem'
    },
});

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.text,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontSize: '1.38rem',
    fontWeight: 700,
    lineHeight: '100%',
    wordWrap: 'break-word',
    marginBottom: '0.83rem',
    '@media (min-width:1024px)': {
        fontSize: '50px',
        width: '60%',
        textAlign: 'left',
    },
}));

const Description = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.text,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontWeight: 400,
    lineHeight: '1.3rem',
    wordWrap: 'break-word',
    marginBottom: '1.11rem',
    '@media (min-width:1024px)': {
        width: '60%',
        textAlign: 'left',
        fontSize: '22px'
    },
}));

// This one does not stretch outside due to bubbles, use as example.
const ImageContainer = styled(Box)({
    width: '82vw',
    height: '110.4vw',
    borderRadius: '18.077px',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    position: 'relative',
    '@media (min-width:1024px)': {
        width: '39vw',
        height: '47.6vw',
        marginTop: '3rem',
        marginBottom: '3rem',
        marginRight: '1rem'
    },
});

const AbsoluteBoxOne = styled(Box)({
    position: 'absolute',
    top: '3.33rem',
    left: 'calc(-15% + (100% - 85%) / 2)',
});

const AbsoluteBoxTwo = styled(Box)({
    position: 'absolute',
    bottom: '3.33rem',
    right: 'calc(-15% + (100% - 85%) / 2)',
})
function HomeNoBs(props) {
    const navigate = useNavigate();

    return (
        <Container>
            <TextContainer>
                <Title>No fillers, no BS, just morels</Title>
                <Description>
                    Each ingredient is sourced following the same belief. Only what is needed. Only the finest that we can find, and only what complements the morel.
                </Description>
                <AppButton onClick={() => navigate("/product")}>
                    Start now
                </AppButton>
            </TextContainer>
            <ImageContainer>
                <AbsoluteBoxOne>
                    {/*<AppBenefitsBubble benefitText="Improved energy" icon={<Bolt />} backgroundColor="#F9D982" />*/}
                </AbsoluteBoxOne>
                <AbsoluteBoxTwo>
                    {/*<AppBenefitsBubble benefitText="Improved energy" icon={<Bolt />}  backgroundColor="#F9D982"/>*/}
                </AbsoluteBoxTwo>
            </ImageContainer>
        </Container>
    );
}

export default HomeNoBs;
