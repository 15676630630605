import React from 'react';
import styled from "@emotion/styled";
import {Box, Typography} from "@mui/material";

const ScrollingTextSection = styled(Box)(({ theme }) => ({
    height: '4.389rem',
    background: theme.palette.tertiary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.389rem',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
}));

const ScrollingTextContainer = styled(Box)({
    display: 'inline-flex',
    position: 'absolute',
    animation: 'scroll 50s linear infinite',
    '@keyframes scroll': {
        '0%': { transform: 'translateX(0)' },
        '100%': { transform: 'translateX(-100%)' },
    },
    gap: '1.39rem',
    width: '100%'
});

const Text = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.black.main,
    fontSize: '1.111rem',
    fontFamily: 'Graebenbach',
    fontWeight: 700,
    lineHeight: '1.222rem',
}));

const scrollingTexts = (
    <>
        <Text>First scrolling text here</Text>
        <Text>Some scrolling text here</Text>
        <Text>Some scrolling text here</Text>
        <Text>Last scrolling text here</Text>
    </>
);
function HomeScroll(props) {
    return (
        <ScrollingTextSection>
            <ScrollingTextContainer>
                {scrollingTexts}
                {scrollingTexts}
                {scrollingTexts}
                {scrollingTexts}
            </ScrollingTextContainer>
        </ScrollingTextSection>
    );
}

export default HomeScroll;
