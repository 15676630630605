import React from 'react';
import {Box, Typography, useMediaQuery} from "@mui/material";
import {ReactComponent as Bolt} from "../../assets/icons/boltIcon.svg";
import {ReactComponent as Target} from "../../assets/icons/targetIcon.svg";
import styled from "@emotion/styled";
import AppButton from "../shared/AppButton";
import AppBenefitsBubble from "../shared/AppBenefitsBubble";
import bgImage from '../../assets/images/girl_laughing.jpg';
import {useNavigate} from "react-router-dom";

const Container = styled(Box)(({ theme }) => ({
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    '@media (min-width:1024px)': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '4.81rem',
    marginBottom: '1.63rem',
    '@media (min-width:1024px)': {
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: 0,
        marginLeft: '4rem',
    },
});

const PictureContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '82vw',
    height: '110.4vw',
    borderRadius: '0.992rem',
    background: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media (min-width:1024px)': {
        width: '39vw',
        height: '45.6vw',
        marginTop: '3rem',
        marginBottom: '3rem',
        marginRight: '1rem'
    },
}));

const AbsoluteBoxOne = styled(Box)({
    position: 'absolute',
    top: '2rem',
    left: 'calc(-15% + (100% - 85%) / 2)',
});

const AbsoluteBoxTwo = styled(Box)({
    position: 'absolute',
    bottom: '3.33rem',
    right: 'calc(-12.5% + (100% - 85%) / 2)',
});

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.text,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontSize: '1.778rem',
    fontWeight: 700,
    wordWrap: 'break-word',
    lineHeight: '100%',
    marginBottom: '0.83rem',
    '@media (min-width:1024px)': {
        textAlign: 'left',
        fontSize: '52px',
        width: '90%',
    },
}));

const Description = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.text,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontWeight: 400,
    lineHeight: '1.3rem',
    wordWrap: 'break-word',
    width: '15.6rem',
    marginBottom: '1.11rem',
    '@media (min-width:1024px)': {
        textAlign: 'left',
        marginBottom: '1.45rem',
        width: '20rem',
    },
}));

function HomeBenefits(props) {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <Container>
            <TextContainer>
                <Title>
                    More than just supplements
                </Title>
                <Description>
                    Engineered for potency, our ground-breaking liquid culture technology outperforms traditional methods, redefining the Mushroom supplement landscape as a whole.
                </Description>
                <AppButton onClick={() => navigate("/product")}>
                    Start now
                </AppButton>
            </TextContainer>
            <Box style={{ width: isDesktop && '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: !isDesktop && '3.89rem'}}>
                <PictureContainer>
                    <AbsoluteBoxOne>
                        {/*<AppBenefitsBubble benefitText="Improved focus" icon={<Target />}/>*/}
                    </AbsoluteBoxOne>
                    <AbsoluteBoxTwo>
                        {/*<AppBenefitsBubble benefitText="Improved energy" icon={<Bolt />}/>*/}
                    </AbsoluteBoxTwo>
                </PictureContainer>
            </Box>

        </Container>
    );
}

export default HomeBenefits;
