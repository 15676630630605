import React, {useEffect, useState} from 'react';
import styled from "@emotion/styled";
import {Box, Typography, useMediaQuery} from "@mui/material";
import {ReactComponent as Arrow} from "../../assets/icons/reviewArrowIcon.svg";
import AppReviewCard from "../shared/AppReviewCard";
import {useApi} from "../../contexts/ApiProvider";


const Container = styled(Box)(({ theme }) => ({
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.offWhite.main,
    overflow: 'hidden',
}));

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '6rem',
    marginBottom: '2.3rem',
    '@media (min-width:1024px)': {
        width: '40%',
        marginBottom: '4rem'
    },
});

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.black.main,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontSize: '1.78rem',
    fontWeight: 700,
    lineHeight: '32px',
    wordWrap: 'break-word',
    '@media (min-width:1024px)': {
        fontSize: '52px',
        lineHeight: '50px',
    },
}));

const CardWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none',
    transition: 'transform 0.4s ease',
    gap: '1rem'
});

const ArrowsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1.25rem',
    width: '80vw',
    marginBottom: '1.25rem'
});

function HomeReview(props) {
    const api = useApi();
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const totalCards = 3;
    const [currentCard, setCurrentCard] = useState(0);
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await api.get('/reviews');
                console.log(response);
                if (response.status === 200) {
                    setReviews(response.body);
                    console.log(response.body)
                } else {
                    console.error('Failed to fetch reviews:', response);
                }
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };
        fetchReviews();
    }, [api]);

    console.log(reviews);

    const handlePrevClick = () => {
        setCurrentCard(current => current > 0 ? current - 1 : 0);
    };

    const handleNextClick = () => {
        setCurrentCard(current => current < totalCards - 1 ? current + 1 : totalCards - 1);
    };
    return (
        <Container>
            <TextContainer>
                <Title>
                    What people are saying about morel extract
                </Title>
            </TextContainer>
            <CardWrapper style={{ transform: `translateX(-${currentCard * (100 / totalCards)}%)` }}>
                <AppReviewCard reviewer="John - 30" title="Amazing Product!" review="“Morel mushrooms are very rare and sought after. We are the first company on the market offering a supplement product. Provide the feeling of experiencing something unique and beneficial in ways that have not been offered yet.”"/>
                {isDesktop && <Arrow style={{ transform: 'rotate(180deg)', cursor: 'pointer', alignSelf: 'center' }} onClick={handlePrevClick} />}
                <AppReviewCard reviewer="Jane - 25" title="Highly Recommended" review="“Morel mushrooms are very rare and sought after. We are the first company on the market offering a supplement product. Provide the feeling of experiencing something unique and beneficial in ways that have not been offered yet.”"/>
                {isDesktop && <Arrow style={{ cursor: 'pointer', alignSelf: 'center' }} onClick={handleNextClick} />}
                <AppReviewCard reviewer="Viola - 23" title="happiness in a bottle." review="“Morel mushrooms are very rare and sought after. We are the first company on the market offering a supplement product. Provide the feeling of experiencing something unique and beneficial in ways that have not been offered yet.”"/>
            </CardWrapper>
            {/*<CardWrapper style={{ transform: `translateX(-${currentCard * (100 / reviews.length)}%)` }}>*/}
            {/*    {reviews.map((review, index) => (*/}
            {/*        <AppReviewCard key={index} reviewer={review.name} title={review.title} review={review.review} />*/}
            {/*    ))}*/}
            {/*    {isDesktop && (*/}
            {/*        <>*/}
            {/*            <Arrow style={{ transform: 'rotate(180deg)', cursor: 'pointer', alignSelf: 'center' }} onClick={handlePrevClick} />*/}
            {/*            <Arrow style={{ cursor: 'pointer', alignSelf: 'center' }} onClick={handleNextClick} />*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*</CardWrapper>*/}
            {!isDesktop && (
                <ArrowsContainer>
                    <Arrow style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={handlePrevClick} />
                    <Arrow style={{ cursor: 'pointer' }} onClick={handleNextClick} />
                </ArrowsContainer>
            )}
        </Container>
    );
}

export default HomeReview;
