import React from 'react';
import styled from "@emotion/styled";
import {Box, Typography, useMediaQuery} from "@mui/material";
import {ReactComponent as ExpandIcon} from "../../assets/icons/expandArrowIcon.svg";
import AppAccordion from "../shared/AppAccordion";

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    height: 'auto',
    '@media (min-width:1024px)': {
    },
}));

const TitleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '4.72rem',
    marginBottom: '1.57rem',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '3.5rem',
        marginTop: '5.72rem',

    },
}));

const AccordionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '0.44rem',
    width: '91%',
    flexDirection: 'column',
    marginBottom: '10rem',
    '@media (min-width:1024px)': {
        width: '72%'
    },
}));

function ProductFaq(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');

    return (
        <Container>
            <TitleContainer>
                <Typography fontWeight="700" lineHeight="100%" fontSize={isDesktop ? "65px" : "2.33rem"}>
                    Got questions?
                </Typography>
            </TitleContainer>
            <AccordionContainer>
                <AppAccordion content="adfadfa" title="How amazing are these mushrooms?" expandIcon={<ExpandIcon />} />
                <AppAccordion content="If you love hot coffee, mix 4 Tbsp coffee with 12 fl. oz. hot water in a drip coffee maker, French press, or pour over. Elevate your coffee with our Think Organic Creamer! Prefer it iced? Pour over A LOT of ice. Or make a cold brew by adding cold water to the grounds and then letting it steep for 24-48 hrs. (We actually made a version with coarse grind just for that! Check out Think Organic Cold Brew Coffee)." title="Are there any side effects?" expandIcon={<ExpandIcon />} />
                <AppAccordion content="adfadfa" title="How amazing are these mushrooms?" expandIcon={<ExpandIcon />} />
                <AppAccordion content="adfadfa" title="How amazing are these mushrooms?" expandIcon={<ExpandIcon />} />
                <AppAccordion content="adfadfa" title="How amazing are these mushrooms?" expandIcon={<ExpandIcon />} />
            </AccordionContainer>
        </Container>
    );
}

export default ProductFaq;
