import React from 'react';
import styled from '@emotion/styled';
import AppCaps from "../shared/AppCaps";
import {ReactComponent as Shield} from "../../assets/icons/shieldIcon.svg";
import {ReactComponent as Bolt} from "../../assets/icons/boltIcon.svg";
import {ReactComponent as Stones} from "../../assets/icons/stonesIcon.svg";
import {Box, Typography} from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 2.83rem 0 3.38rem',
    backgroundColor: theme.palette.offWhite.main,
    '@media (min-width:1024px)': {
        padding: '0 2.83rem 0 2.38rem',
        height: 'auto',
    },
}));

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.black,
    textAlign: 'center',
    fontFamily: 'Graebenbach',
    fontSize: '1.778rem',
    fontWeight: 700,
    lineHeight: '100%',
    wordWrap: 'break-word',
    marginTop: '4.4rem',
    '@media (min-width:1024px)': {
        fontSize: '52px',
        marginTop: '5.25rem'
    },
}));

const CapsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.94rem',
    marginTop: '1.62rem',
    marginBottom: '4.65rem',
    '@media (min-width:1024px)': {
        flexDirection: 'row',
        marginTop: '4.3rem',
        marginBottom: '5rem',
        gap: '0.5rem'
    },
}));
function HomeCaps(props) {
    return (
        <Container>
            <Title>
                The health boost you need
            </Title>
            <CapsContainer>
                <AppCaps innerIcon={<Stones />} title="Adaptogentic" description="Restore balance and feel your best by harnessing adaptogens." />
                <AppCaps innerIcon={<Shield />} title="Potent antioxidant" description="Combat free radicals the natural way with an organically grown ingredient you can trust." />
                <AppCaps innerIcon={<Bolt />} title="Cognition support" description="Experience the power of well-supported cognition." />
            </CapsContainer>
        </Container>
    );
}

export default HomeCaps;
