import React from 'react';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {Box, Typography, useMediaQuery} from "@mui/material";
import AppHeader from "../shared/AppHeader";
import AppButton from "../shared/AppButton";
import bannerImage from '../../assets/images/bannerImage.jpg';
import bannerImageDesk from '../../assets/images/bannerImageDesk.jpg'
import {useApi} from "../../contexts/ApiProvider";

const Banner = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '45rem',
    '@media (max-width:1024px)': {
        background: `url(${bannerImage}) no-repeat `,
        backgroundSize: 'cover',
    },
    '@media (min-width:1024px)': {
        background: `url(${bannerImageDesk}) no-repeat center `,
        backgroundPosition: '70% center',
        backgroundSize: 'cover',
    },
}));

const TextContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3.29rem',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20.03rem',
    '@media (min-width:1024px)': {
        marginBottom: 0,
        width: '50%',
    },
}));

const Image = styled(Box)(({ theme }) => ({
    display: 'none',
}));

function HomeBanner(props) {
    const navigate = useNavigate();
    const isSmallMobile = useMediaQuery('(max-width:372px)');
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const api = useApi()



    // const testDB = async () => {
    //     const data = {
    //         email: 'testing',
    //         password: 'testing'
    //     }
    //     try {
    //         const response = await api.post('/users', data);
    //         console.log(response)
    //         if (response.ok) {
    //             console.log('good db')
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const testDB = async () => {
        try {
            const response = await api.get('/reviews');
            console.log(response)
            if (response.ok) {
                console.log('good db')
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <AppHeader />
            <Banner>
                <TextContainer style={{ width: isSmallMobile && 'auto'}}>
                    <Typography fontSize={isDesktop? "68px" : "2.33rem"} fontWeight="700" style={{width: isDesktop && '70%', lineHeight: '2.3rem', marginBottom: isDesktop ? '1.5rem':'0.833rem', textAlign: 'center'}}>
                        Discover the power of nature
                    </Typography>
                    <Typography style={{ lineHeight: '1.3rem', marginBottom: '1.11rem', textAlign: 'center', width: isDesktop && '87%'}}>
                        Morel mushroom extract is a never-seen-before product that will take your health and wellness to new heights. This rare ingredient is a potent antioxidant backed by science and harvested to support immunity and cognition.
                    </Typography>
                    <AppButton onClick={() => navigate("/product")}>
                        Start now
                    </AppButton>
                    {/*<AppButton onClick={testDB}>*/}
                    {/*    Start now*/}
                    {/*</AppButton>*/}
                </TextContainer>
            </Banner>
        </>
    );
}

export default HomeBanner;
