import React from 'react';
import styled from "@emotion/styled";
import HomeBanner from "../components/home/HomeBanner";
import HomeScroll from "../components/home/HomeScroll";
import HomeCaps from "../components/home/HomeCaps";
import HomeBenefits from "../components/home/HomeBenefits";
import HomeLiquid from "../components/home/HomeLiquid";
import HomeNoBS from "../components/home/HomeNoBS";
import HomeFirst from "../components/home/HomeFirst";
import HomeReview from "../components/home/HomeReview";
import {Box} from "@mui/material";
import HomeFooter from "../components/home/HomeFooter";

const Container = styled(Box)({
    margin: 'auto',
    maxWidth: '1800px',
})

function HomePage(props) {
    return (
        <Container>
            <HomeBanner />
            <HomeScroll />
            <HomeCaps />
            <HomeBenefits />
            <HomeLiquid />
            <HomeNoBS />
            <HomeFirst />
            <HomeReview />
            <HomeFooter />
        </Container>
    );
}

export default HomePage;
